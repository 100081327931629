import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rating-static',
  templateUrl: './star-rating-static.component.html',
  styleUrls: ['./star-rating-static.component.scss']
})
export class StarRatingStaticComponent {
  @Input() rating: number;
  @Input() itemId: number;

}
