import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { AppService } from 'src/app/app.service';
import { NotificationService } from 'src/app/notification.service';
import { Subject, interval } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { FileUploadModel } from 'src/app/models/models';
@Component({
  selector: 'app-logistic-partner-home',
  templateUrl: './logistic-partner-home.component.html',
  styleUrls: ['./logistic-partner-home.component.scss']
})
export class LogisticPartnerHomeComponent implements OnInit, OnDestroy {
  destroyed = new Subject<void>();

  profileLocked = false;
  licenseExpired = false;

  fileRegistration: FileUploadModel;
  fileDLCard: FileUploadModel;

  isInProgressRegistration = false;
  isInProgressDLCard = false;

  fileNameRegistration = '';
  fileNameDLCard = '';

  uploadResponseRegistration = { status: '', message: '', filePath: '' };
  uploadResponseDLCard = { status: '', message: '', filePath: '' };
  error: string;
  userSub: Subscription;
  saveUserSub: Subscription = null;
  user: any;



  // docs
  registrationImgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  licenseImgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };


  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
              private titleService: Title, private router: Router,
              private notificationService: NotificationService,
              public appService: AppService) { 

              }

  ngOnInit(): void {
    this.titleService.setTitle('Logistics Partner - Jhelumcart Online Pvt. Ltd.');
    this.spinner.show();
    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;
    console.log(username);
    this.userSub = this.usersService.getUserByUsername(username).subscribe(res => {
      this.profileLocked = res.isActive === false;
      // this.profileLocked = true;
      this.licenseExpired = res.licenseExpired;
      this.user = res;
      if (!this.profileLocked) {
        this.getNofications(true);
        this.startInterval();
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  
  startInterval() {
    // console.log('startig');
    interval(10 * 1000)
    .pipe(
      takeUntil(this.destroyed),
      map(() => this.getNofications(false))
    ).subscribe();
  }


  getNofications(firstTime) {
    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;
    this.notificationService.getUnreadNotificationsCountForUser(username).subscribe(res => {
      this.appService.Data.unreadNotificationsCount = res;
      this.spinner.hide();
      const audio = new Audio('https://api.jhelumcart.com/notification.mp3');
      if (firstTime) {
        this.appService.Data.unreadNotificationsCountPrevious = res;
      }
      if (!firstTime) {
        if (this.appService.Data.unreadNotificationsCountPrevious !== this.appService.Data.unreadNotificationsCount) {
          audio.play();
          this.appService.Data.unreadNotificationsCountPrevious = this.appService.Data.unreadNotificationsCount;
        }
      }
    }, err => {
      this.spinner.hide();
    });
  }
  gotoNotifications() {
    this.router.navigate(['/logisticpartner/notifications']);
  }


  gotoProfile() {
    this.router.navigate(['/logisticpartner/profile']);
  }

  gotoChangePassword() {
    this.router.navigate(['/logisticpartner/change-password']);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/sign-in']);
  }

  saveProfile() {
    if (this.fileNameRegistration == '') {
      Swal.fire('Error', 'Registration Document Image is required.');
          return;
    }

    if (this.fileNameDLCard == '') {
      Swal.fire('Error', 'PAN Card Image is required.');
          return;
    }

    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;


    this.user.registrationDocumentImagePath = 'LogisticPartner/' + username + '/Documents/' + this.fileNameRegistration;
    this.user.licenseImagePath = 'LogisticPartner/' + username + '/Documents/' + this.fileNameDLCard;
    this.user.modifiedBy = username;
    this.spinner.show();
    this.saveUserSub = this.usersService.updateUser(this.user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', 'Images saved successfully.', 'success');
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    if (this.saveUserSub != null) {
      this.saveUserSub.unsubscribe();
    }
    this.destroyed.next();
  }


  onClickRegistrationImage() {
    const fileUpload = document.getElementById('fileUploadRegistrationImage') as HTMLInputElement;
    fileUpload.onchange = () => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }
      this.registrationImgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.registrationImgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG") ) {
        this.registrationImgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 200) {
          this.registrationImgValidations.validSize = false;
      }

      
      if (!this.registrationImgValidations.validSize ||
        !this.registrationImgValidations.validType) {

          let errorMsg = "";
          if (!this.registrationImgValidations.validSize) {
            errorMsg += "<div>Invalid file size</div>";
          }
          if (!this.registrationImgValidations.validType) {
            errorMsg += "<div>Invalid file type</div>";
          }
          Swal.fire('Error', errorMsg, 'error');
      // console.log('returning one or more validations failed.........');
      return;
    } else  {
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      // console.log(formData);
      this.fileRegistration = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadRegistrationDocument(formData);
    }
     
    };
    fileUpload.click();
  }

  private uploadRegistrationDocument(formData: FormData) {
    this.usersService.uploadLogisticPartnerDocument(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponseRegistration = res;
          this.isInProgressRegistration = true;
        }
        this.fileNameRegistration = this.fileRegistration.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onClickDLCardImage() {
    const fileUpload = document.getElementById('fileUploadDLCardImage') as HTMLInputElement;
    fileUpload.onchange = () => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }
      this.licenseImgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.licenseImgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG") ) {
        this.licenseImgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 200) {
        this.licenseImgValidations.validSize = false;
      }

      if (!this.licenseImgValidations.validSize ||
        !this.licenseImgValidations.validType) {

          let errorMsg = "";
          if (!this.licenseImgValidations.validSize) {
            errorMsg += "<div>Invalid file size</div>";
          }
          if (!this.licenseImgValidations.validType) {
            errorMsg += "<div>Invalid file type</div>";
          }
          Swal.fire('Error', errorMsg, 'error');
          // console.log('returning one or more validations failed.........');
          return;
        } else  {
          const formData: FormData = new FormData();
          formData.append('uploadFile', file, file.name);
          // console.log(formData);
          this.fileDLCard = {
            data: file, state: 'in',
            inProgress: false, progress: 0, canRetry: false, canCancel: true
          };
          this.uploadDLCardImage(formData);
        }

    };
    fileUpload.click();
  }

  private uploadDLCardImage(formData: FormData) {
    this.usersService.uploadLogisticPartnerDocument(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponseDLCard = res;
          this.isInProgressDLCard = true;
        }
        this.fileNameDLCard = this.fileDLCard.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

}
