import { Title } from '@angular/platform-browser';
import { User, TokenAuth, Country, OTPResult, State, UserDTO } from './../models';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from './../users.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator, matchingPasswords } from '../theme/utils/app-validators';
import { AppService } from '../app.service';
import { CountriesService } from '../countries.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { StatesService } from '../states.service';

@Component({
  selector: 'app-delivery-boy-register',
  templateUrl: './delivery-boy-register.component.html',
  styleUrls: ['./delivery-boy-register.component.scss']
})
export class DeliveryBoyRegisterComponent implements OnInit {

  form: FormGroup;
  otpSent: boolean = false;
  otpCount: number = 0;
  mobileVerified: boolean = false;
  otpResult: OTPResult;

  countriesSub: Subscription;
  statesSub: Subscription;
  countries: Country[];
  states: State[];
  countryId: string = '';
  stateId: string = '';

  constructor(public appService: AppService, public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar,
    private userService: UsersService, private spinner: NgxSpinnerService, private titleService: Title,
    private countriesService: CountriesService,
    private statesService: StatesService,
    private usersService: UsersService) {
  }

  ngOnInit() {
    this.titleService.setTitle('Register - ' + this.appService.Data.title);


    this.form = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      address: ['', Validators.required],
      mobile: ['', Validators.pattern('[0-9]{9,20}')],
      otp: ['', Validators.required],
      country: new FormControl({ value: '', disabled: true }, Validators.required),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      email: ['', Validators.compose([Validators.required, emailValidator])],
      alternateMobile: ['', Validators.pattern('[0-9]{9,20}')],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: matchingPasswords('password', 'confirmPassword') });

    this.bindCountries();
  }


  isDisabledSendOTP(){
    return this.form.value.mobile == '' || this.otpSent == true;
  }
  isDisabledResendOTP(){
    return this.form.value.mobile == ''
    || this.otpCount == 2 || this.otpSent == false;
  }
  isDisabledVerifyOTP(){
    return this.form.value.mobile == ''
    || this.form.value.otp == '' || this.mobileVerified == true;
  }

  sendOTP() {

    
    const country = this.countries.filter(c => c.id == this.form.controls['country'].value)[0];
    const phoneCode = country.phoneCode;
    const mobileNumber = phoneCode + this.form.value.mobile;

    this.spinner.show();

    this.usersService.sendOTP(mobileNumber).subscribe(res => {
      this.otpResult = res;
      this.otpSent = true;
      this.otpCount++;
      this.spinner.hide();
      Swal.fire('Message', 'OTP has been sent to your Mobile Number. Kindly enter it in the box below.', 'success');
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }
  verifyOTP() {
    const otp = this.form.value.otp;
    const sessionID = this.otpResult.Details;

    this.spinner.show();
    this.usersService.verifyOTP(otp, sessionID).subscribe(res => {
      this.spinner.hide();
      if (res.Status.indexOf('Success') != -1 &&
          res.Details.indexOf('OTP Matched') != -1) {
            this.mobileVerified = true;
            Swal.fire('Message', 'Mobile No. Verified successfully.', 'success');
          } else {
            Swal.fire('Error', 'OTP not matched. Please enter correct OTP...', 'error');
          }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }
  


  public onformSubmit(): void {
    if (this.otpResult === null || this.otpResult === undefined) {
      Swal.fire('Error', 'OTP not sent. Press Send OTP first and enter OTP in the box below.', 'error');
      return;
    }
    const user: User = {
      mobile: this.form.value.mobile,
      name: this.form.value.name,
      email: this.form.value.email,
      alternateMobile: this.form.value.alternateMobile,
      address: this.form.value.address,
      username: this.form.value.mobile,
      password: this.form.value.password,
      countryID: +this.form.controls['country'].value,
      stateID: +this.form.controls['state'].value,
      role: 'LogisticPartner'
    };

    const otp = this.form.value.otp;
    const sessionID = this.otpResult.Details;

    const userDTO: UserDTO = {
      user,
      otp,
      sessionID
    };

    this.spinner.show();
    this.userService.addUser(userDTO).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        const auth: TokenAuth = {
          username: this.form.value.mobile,
          password: this.form.value.password,
          grant_type: 'password'
        };

        this.userService.login(auth).subscribe(resLogin => {
          localStorage.setItem('token',
            JSON.stringify(resLogin));
          this.appService.Data.loggedIn = true;
          this.router.navigate(['/logisticpartner']);
        });
      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, err => {
      this.spinner.hide();
      this.snackBar.open('Error occured. Please try again...', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });

  }

  bindCountries() {
    this.spinner.show();
    this.countriesSub = this.countriesService.getCountries().subscribe(res => {
      this.countries = res;
      this.form.patchValue({
        country: res.filter(c => c.id === 101)[0].id
      });
      this.spinner.hide();
      this.bindStates();
    }, err => {
      this.spinner.hide();
    });
  }

  bindStates() {
    if (this.statesSub != null) {
      this.statesSub.unsubscribe();
    }
    this.spinner.show();
    this.statesSub = this.statesService.getByCountry(+this.form.controls['country'].value).subscribe(res => {
      this.states = res;
      this.form.patchValue({
        state: res.filter(s => s.id === 15)[0].id
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

}