import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import {saveAs} from 'file-saver';
// in prod
// import * as Excel from "exceljs/dist/exceljs.min.js";
// in prod
// import * as ExcelProper from "exceljs";


@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  fileUrl;
  constructor() { }

  generateExcel(filename, title, header, data) {
    // console.log('generating');
    //  Create workbook and worksheet

    // in local
    // no need to uncomment..
    const workbook = new Workbook();

    // in prod
    // const workbook: ExcelProper.Workbook = new Excel.Workbook();
    // console.log('workbook', workbook);
    const sheetName = filename;
    const worksheet = workbook.addWorksheet(sheetName);


    //  Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    titleRow.font = { size: 16, bold: true };
    // Blank Row
    //  worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    //  Cell Style : Fill and Border
    headerRow.eachCell((cell, index) => {
      // cell.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: '000FF000' },
      //   bgColor: { argb: '000FF000' }
      // };
      // console.log(index);
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });



    //  Add Data and Conditional Formatting
    data.forEach((rowData, rowIndex) => {
      rowIndex++;
      const textData = [];
      const imgs: { b64: any, index: number, type: 'jpeg' | 'png' | 'gif' }[] = [];
      let textColuns = 0;
      rowData.forEach((d, i) => {
        // data:image/jpeg
        let imgCol = false;
        if (String(d).startsWith('data:image/png')) {
          imgs.push({ b64: d, index: (i), type: 'png' });
          textData.push('');
          imgCol = true;
        } else if (String(d).startsWith('data:image/jpeg')) {
          imgs.push({ b64: d, index: (i), type: 'jpeg' });
          textData.push('');
        } else {
          textData.push(d);
          ++textColuns;
        }
        const colNum = i + 1;
        if (imgCol) {
          worksheet.getColumn(colNum).width = 20;
        } else {
          worksheet.getColumn(colNum).width = 15;
        }
      });


      const row = worksheet.addRow(textData);
      if (imgs.length > 0) {
        worksheet.getRow(row.number).height = 32;
      }

      row.eachCell((cell, index) => {
        // console.log(index);
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });

      // row number from where rows data will start
      // first row is title row
      // second row is heading row
      // thus add +2 to rowIndex (as first two rows are used)
      const rowNum = rowIndex + 2;


      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < imgs.length; i++) {
        const cellAddr = this.getColumnName(imgs[i].index) + (rowNum);
        // console.log(cellAddr);
        const range = `${cellAddr}:${cellAddr}`;
        const pic = workbook.addImage({
          base64: imgs[i].b64,
          extension: imgs[i].type,
        });
        worksheet.addImage(pic, range);

        // worksheet.getColumn(imgs[i].index + 1).width = 25;
        // worksheet.getRow(imgs[i].index + 1).height = 30;
      }

      // worksheet.getColumn(rowNum).width = 25;
    });

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);


    // console.log(this.convert(new Date().toString()));
    const dateCreated = `Report Generated @ ${this.convert(new Date())}`;
    const footerRow = worksheet.addRow([dateCreated]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    // tslint:disable-next-line:max-line-length
    // footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:C${footerRow.number}`);


    // const createdBy = `Generated by : ${this.token.getUserName()}`;
    const createdBy = `Generated by : Admin`;
    const footerRow2 = worksheet.addRow([createdBy]);
    footerRow2.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
    // tslint:disable-next-line:max-line-length
    // footerRow2.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // Merge Cells
    worksheet.mergeCells(`A${footerRow2.number}:C${footerRow2.number}`);



    //  const data = 'some tespnitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

    // console.log('about to wrtite....................');
    //  Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename);
    });

  }

  convert(date: Date) {
    // const dateStr = d.toString();
    // const date = new Date(dateStr);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const ddmmyyy = [day, mnth, date.getFullYear()].join('-');

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const secondsStr = seconds < 10 ? '0' + seconds : seconds;
    const hhMMSSampm = hours + ':' + minutesStr + ':' + secondsStr + ' ' + ampm;

    return ddmmyyy + ' ' + hhMMSSampm;
  }

  getColumnName(n) {
    const ordA = 'A'.charCodeAt(0);
    const ordZ = 'Z'.charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = '';
    while (n >= 0) {
      s = String.fromCharCode(n % len + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }
}
