import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from './models';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient) { }

  getCountries(): Observable<Country[]> {
    const url = BaseApiService.baseApiUrl + 'api/Country/Get';

    return this.http.get<Country[]>(url);
  }
}
