import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: any = null;
  constructor(private usersService: UsersService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.spinner.show();
    this.usersService.getUserByUsername(username).subscribe(res => {
      this.user = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

}
