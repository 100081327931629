import { Title } from '@angular/platform-browser';
import { User, TokenAuth, Country, OTPResult, State, UserDTO } from './../models';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from './../users.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator, matchingPasswords } from '../theme/utils/app-validators';
import { AppService } from '../app.service';
import { CountriesService } from '../countries.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { StatesService } from '../states.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  displaybtn = true;

  // secretKey = '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe';
  secretKey = '6Lftsj4UAAAAAFx7LVc8XxTugqEGEJwq5l-wio4_';

  captchaVerified = false;
  countriesSub: Subscription;
  statesSub: Subscription;
  countries: Country[];
  states: State[];
  countryId: string = '';
  stateId: string = '';
  otpSent: boolean = false;
  otpCount: number = 0;
  mobileVerified: boolean = false;
  otpResult: OTPResult;
  constructor(public appService: AppService, public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar,
    private userService: UsersService, private spinner: NgxSpinnerService, private titleService: Title,
    private countriesService: CountriesService,
    private statesService: StatesService,
    private usersService: UsersService) {
  }

  ngOnInit() {
    this.titleService.setTitle('Sign In - ' + this.appService.Data.title);
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      mobile: ['', Validators.pattern('[0-9]{9,20}')],
      otp: ['', Validators.required],
      country: new FormControl({ value: '', disabled: true }, Validators.required),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      email: ['', Validators.compose([Validators.required, emailValidator])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: matchingPasswords('password', 'confirmPassword') });

    this.bindCountries();
  }



  public onRegisterFormSubmit(): void {

    if (this.otpResult === null || this.otpResult === undefined) {
      Swal.fire('Error', 'OTP not sent. Press Send OTP first and enter OTP in the box below.', 'error');
      return;
    }

    const user: User = {
      mobile: this.registerForm.value.mobile,
      name: this.registerForm.value.name,
      email: this.registerForm.value.email,
      username: this.registerForm.value.mobile,
      password: this.registerForm.value.password,
      countryID: +this.registerForm.controls['country'].value,
      stateID: +this.registerForm.controls['state'].value,
      role: 'User',
      isActive: true
    };

    const otp = this.registerForm.value.otp;
    const sessionID = this.otpResult.Details;

    const userDTO: UserDTO = {
      user,
      otp,
      sessionID
    };

    this.spinner.show();
    this.userService.addUser(userDTO).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        const auth: TokenAuth = {
          username: this.registerForm.value.mobile,
          password: this.registerForm.value.password,
          grant_type: 'password'
        };


      } else {
        this.snackBar.open(res.message, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, err => {
      this.spinner.hide();
      this.snackBar.open('Error occured. Please try again...', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });

  }

  doLogin() {
    const auth: TokenAuth = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
      grant_type: 'password'
    };

    this.spinner.show();

    this.userService.login(auth).subscribe(resLogin => {
      // console.log(resLogin);
      localStorage.setItem('token', JSON.stringify(resLogin));
      this.appService.Data.loggedIn = true;
      this.spinner.hide();
      this.displaybtn = false;
      if (resLogin.role === 'LogisticPartner') {
        this.router.navigate(['/logisticpartner']);
      } else {
        this.displaybtn = true;
        this.snackBar.open('Invalid Username/ Password. Please try again...', 'x',
        { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    }, error => {
      this.spinner.hide();
      this.snackBar.open('Invalid Username/ Password. Please try again...', 'x',
        { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != null) {
      this.userService.verifyCaptcha(captchaResponse, this.secretKey).subscribe(res => {
        if (res) {
          this.captchaVerified = true;
        } else {
          this.captchaVerified = false;
        }
      });
    } else {
      this.captchaVerified = false;
    }
  }

  isDisabledSendOTP() {
    return this.registerForm.value.mobile == '' || this.otpSent == true;
  }
  isDisabledResendOTP() {
    return this.registerForm.value.mobile == ''
      || this.otpCount == 2 || this.otpSent == false;
  }
  isDisabledVerifyOTP() {
    return this.registerForm.value.mobile == ''
      || this.registerForm.value.otp == '' || this.mobileVerified == true;
  }


  verifyOTP() {
    const otp = this.registerForm.value.otp;
    const sessionID = this.otpResult.Details;

    this.spinner.show();
    this.usersService.verifyOTP(otp, sessionID).subscribe(res => {
      this.spinner.hide();
      if (res.Status.indexOf('Success') != -1 &&
        res.Details.indexOf('OTP Matched') != -1) {
        this.mobileVerified = true;
        Swal.fire('Message', 'Mobile No. Verified successfully.', 'success');
      } else {
        Swal.fire('Error', 'OTP not matched. Please enter correct OTP...', 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  sendOTP() {
    const country = this.countries.filter(c => c.id == this.registerForm.controls['country'].value)[0];
    const phoneCode = country.phoneCode;
    const mobileNumber = phoneCode + this.registerForm.value.mobile;

    this.spinner.show();

    this.usersService.sendOTP(mobileNumber).subscribe(res => {
      this.otpResult = res;
      this.otpSent = true;
      this.otpCount++;
      this.spinner.hide();
      Swal.fire('Message', 'OTP has been sent to your Mobile Number. Kindly enter it in the box below.', 'success');
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  bindCountries() {
    this.spinner.show();
    this.countriesSub = this.countriesService.getCountries().subscribe(res => {
      this.countries = res;
      this.registerForm.patchValue({
        country: res.filter(c => c.id === 101)[0].id
      });
      this.spinner.hide();
      this.bindStates();
    }, err => {
      this.spinner.hide();
    });
  }

  bindStates() {
    if (this.statesSub != null) {
      this.statesSub.unsubscribe();
    }
    this.spinner.show();
    this.statesSub = this.statesService.getByCountry(+this.registerForm.controls['country'].value).subscribe(res => {
      this.states = res;
      this.registerForm.patchValue({
        state: res.filter(s => s.id === 15)[0].id
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

}