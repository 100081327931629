import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';

@Component({
  selector: 'app-logistic-partner-default',
  templateUrl: './logistic-partner-default.component.html',
  styleUrls: ['./logistic-partner-default.component.scss']
})
export class LogisticPartnerDefaultComponent implements OnInit, OnDestroy {

  profileLocked = false;
  licenseExpired = false;

  error: string;
  userSub: Subscription;
  saveUserSub: Subscription = null;
  user: any;




  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
              private titleService: Title, private router: Router) { }

  ngOnInit(): void {
    this.titleService.setTitle('Logistic-Partner Dashboard - Jhelumcart Online Pvt. Ltd.');
    // this.spinner.show();
    let username = '';
    username = JSON.parse(localStorage.getItem('token')).username;
    // this.userSub = this.usersService.getUserByUsername(username).subscribe(res => {
    //   this.profileLocked = res.isActive === false;
    //   this.licenseExpired = res.licenseExpired;
    //   this.user = res;
    //   this.spinner.hide();
    // }, err => {
    //   this.spinner.hide();
    // });
  }
  

  gotoProfile() {
    this.router.navigate(['/seller/profile']);
  }

  gotoChangePassword() {
    this.router.navigate(['/seller/change-password']);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/sign-in']);
  }

  ngOnDestroy() {
    if (this.userSub != null) {
      this.userSub.unsubscribe();
    }
    if (this.saveUserSub != null) {
      this.saveUserSub.unsubscribe();
    }
  }





}
