import { Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Input, Output, DoCheck } from '@angular/core';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, DoCheck {
  @Input() categories;
  @Input() categoryParentId;
  // tslint:disable-next-line: no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories;

  constructor(private router: Router) { }
  ngOnInit(): void {
  }

  public ngDoCheck() {
    if (this.categories && !this.mainCategories) {
      this.mainCategories = this.categories.filter(category => category.parentId === this.categoryParentId);
    }
  }

  public stopClickPropagate(event: any) {
    if (window.innerWidth < 960) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public changeCategory(event, url) {
    if (url !== '') {
      this.router.navigate([url]);
    }
  }

}
