import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../users.service';
import { matchingPasswords } from '../theme/utils/app-validators';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: matchingPasswords('password', 'confirmPassword') });
  }

  resetPassword() {
    this.spinner.show();
    this.activatedRoute.queryParams.subscribe(params => {
      const email = decodeURIComponent(params['q'])
      this.usersService.resetPassword(email, this.form.value.password).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', res.message, 'success');
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, err => {
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
    })
  }

}
