import { Contact } from './models';
import { BaseApiService } from 'src/app/base-api.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  getContacts(): Observable<Contact[]> {
    const url = BaseApiService.baseApiUrl + 'api/Contact/Get';

    return this.http.get<Contact[]>(url);
  }

  getContact(id: number): Observable<Contact> {
    const url = BaseApiService.baseApiUrl + 'api/Contact/Get/' + id;

    return this.http.get<Contact>(url);
  }

  addContact(contact: Contact): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/Contact/Post';

    return this.http.post<boolean>(url, contact);
  }
}
