import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrdersService } from 'src/app/orders.service';

@Component({
  selector: 'app-logistic-partner-dashboard',
  templateUrl: './logistic-partner-dashboard.component.html',
  styleUrls: ['./logistic-partner-dashboard.component.scss']
})
export class LogisticPartnerDashboardComponent implements OnInit {

  totalWebsiteOrders = 0;
  pendingWebsiteOrders = 0;
  dispatchedWebsiteOrders = 0;
  deliveredWebsiteOrders = 0;
  totalCustomOrders = 0;
  pendingCustomOrders = 0;
  dispatchedCustomOrders = 0;
  deliveredCustomOrders = 0;
  expressCustomOrders = 0;
  totalRequestOrders = 0;
  pendingRequestOrders = 0;
  dispatchedRequestOrders = 0;
  deliveredRequestOrders = 0;
  expressRequestOrders = 0;
  constructor(private ordersService: OrdersService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindOrdersCount();
  }

  bindOrdersCount() {
    this.spinner.show();
    this.ordersService.getOrdersCountForLogisticPartner().subscribe(res => {
      
      this.totalWebsiteOrders = res.totalWebsiteOrdersCount;
      this.pendingWebsiteOrders = res.pendingWebsiteOrdersCount;
      this.dispatchedWebsiteOrders = res.dispatchedWebsiteOrdersCount;
      this.deliveredWebsiteOrders = res.deliveredWebsiteOrdersCount;

      this.totalCustomOrders = res.totalCustomOrdersCount;
      this.pendingCustomOrders = res.pendingCustomOrdersCount;
      this.dispatchedCustomOrders = res.dispatchedCustomOrdersCount;
      this.deliveredCustomOrders = res.deliveredCustomOrdersCount;
      this.expressCustomOrders = res.expressCustomOrdersCount;

      this.totalRequestOrders = res.totalRequestOrdersCount;
      this.pendingRequestOrders = res.pendingRequestOrdersCount;
      this.dispatchedRequestOrders = res.dispatchedRequestOrdersCount;
      this.deliveredRequestOrders = res.deliveredRequestOrdersCount;
      this.expressRequestOrders = res.expressRequestOrdersCount;

      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

}
