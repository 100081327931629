import { PipesModule } from './theme/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppService } from './app.service';
import { AppSettings } from './app-settings';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, forwardRef } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { AppSidenavMenuComponent } from './app-sidenav-menu/app-sidenav-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { MenuComponent } from './menu/menu.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { OptionsComponent } from './options/options.component';
import { FooterComponent } from './footer/footer.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { ControlsComponent } from './controls/controls.component';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { RatingComponent } from './rating/rating.component';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { PaginationControlsComponent } from './pagination-controls/pagination-controls.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/aboutus.component'
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SellerCarouselComponent } from './seller-carousel/seller-carousel.component';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { StarRatingStaticComponent } from './star-rating-static/star-rating-static.component';
import { BannersComponent } from './banners/banners.component';
import { SizeChartComponent } from './size-chart/size-chart.component';
import { CustomerTcComponent } from './customter-tc/customer-tc.component';
import { OurPricingComponent } from './our-pricing/our-pricing.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReturnRefundPolicyComponent } from './return-refund-policy/return-refund-policy.component';
import { SellerTcComponent } from './seller-tc/seller-tc.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeliveryBoyRegisterComponent } from './delivery-boy-register/delivery-boy-register.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    CustomerTcComponent,
    OurPricingComponent,
    PrivacyPolicyComponent,
    ReturnRefundPolicyComponent,
    SellerTcComponent,
    AppSidenavMenuComponent,
    TopMenuComponent,
    CategoryListComponent,
    MenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    MenuItemComponent,
    ControlsComponent,
    ProductDialogComponent,
    SearchDialogComponent,
    RatingComponent,
    ProductZoomComponent,
    SignInComponent,
    PaginationControlsComponent,
    ContactComponent,
    SellerCarouselComponent,
    StarRatingComponent,
    StarRatingStaticComponent,
    BannersComponent,
    SizeChartComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DeliveryBoyRegisterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxPaginationModule,
    SwiperModule,
    PipesModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCRX8CHmtjncXoDTYt3533BEiZCBgeyhKo'
    // }),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule 
    
  ],
  providers: [
    AppSettings,
    AppService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SignInComponent),
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
