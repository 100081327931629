import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  ProductAttribute, Stock } from './models';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private http: HttpClient) { }

  // updates the selected stock
  addStock(stock: Stock): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/post';
    const data = {
      productID: stock.productID,
      physicalStock: stock.physicalStock,
      color: stock.color,
      size: stock.size,
      weight: stock.weight,
      brand: stock.brand
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, data, {headers});
  }

  // updates the selected stock
  updateStock(stock: Stock): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/put';
    const data = {
      id: stock.id,
      productID: stock.productID,
      physicalStock: stock.physicalStock,
      color: stock.color,
      size: stock.size,
      weight: stock.weight,
      brand: stock.brand
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, data, {headers});
  }

  // gets all stocks from api
  getStocks(): Observable<Stock[]> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/get';
    return this.http.get<Stock[]>(url);
  }

  getStockRegisterBySeller(username: string): Observable<Stock[]> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/GetStockRegisterBySeller?username=' + username;

    return this.http.get<Stock[]>(url);
  }

  // get stock by product id
  getByProduct(id: number): Observable<Stock[]> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/get/' + id;
    return this.http.get<Stock[]>(url);
  }

  // get stock by id
  getById(id: number): Observable<Stock> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/GetById/' + id;
    return this.http.get<Stock>(url);
  }

  // get stock by product id and quantity
  getByParameters(id: number, quantity: number, color: string, size: string, weight: string): Observable<ProductAttribute> {
    const url = BaseApiService.baseApiUrl + 'api/Stock/GetByParameters?id=' +
                id + '&quantity=' + quantity + '&color=' + encodeURIComponent(color) + '&size=' + size +
                '&weight=' + weight;
    
                console.log(url);
    return this.http.get<ProductAttribute>(url);
  }

}
