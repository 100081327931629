import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseApiService } from 'src/app/base-api.service';
import { ExpensesService } from 'src/app/expenses.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {
  form: FormGroup;
  fromDate: Date;
  toDate: Date;
  expenses: any[] = [];
  currentPage = 1;
  totalItems = 0;
  pageSize = 10;
  sortBy = 'dateFrom';
  direction = 'desc';
  totalExpenses = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.expenses);
  displayedColumns: string[] = ['expenseDate', 'expenseName', 'amount', 'imagePath', 'actionDetails'];

  constructor(private expenseService: ExpensesService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder,
              private datePipe: DatePipe,
              private router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      fromDate: [''],
      toDate: ['']
    });
    this.toDate = new Date();
    const d = new Date();
    d.setDate(this.toDate.getDate() - 6);
    this.fromDate = d;
    this.initVals();
    this.bindExpenses();
  }

  initVals() {
    this.currentPage = 1;
    this.totalItems = 0;
    this.expenses = [];
  }

  bindExpenses() {
    this.spinner.show();
    this.expenseService.getExpensesByUsernameAndDateRange(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
    this.datePipe.transform(this.toDate, 'MM/dd/yyyy'), this.currentPage - 1, this.pageSize)
    .subscribe(res => {
      this.spinner.hide();
      this.expenses = res.records;
      this.totalItems = res.totalItems;
      this.totalExpenses = res.total;
      this.dataSource = new MatTableDataSource<any>(this.expenses);
      this.expenses.forEach(expense => {
        expense.isEditing = false;
      });
    }, err => {
      this.spinner.hide();
      if (err.status == 401) {
        this.router.navigateByUrl('/');
      } else {
        alert('Error occured. Please try again...' + err.statusText);
      }
    });
  }

  updateExpense(expense: any) {
    console.log('Expense: ', expense);
    this.spinner.show();
    this.expenseService.updateExpense(expense)
    .subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        expense.isEditing = false;
        this.bindExpenses();
      }
      alert(res.message);
    }, err => {
      this.spinner.hide();
      if(err.status == 401) {
        this.router.navigateByUrl('/');
      } else {
        alert('Error occured. Please try again...' + err.statusText);
      }
    });
  }

  doSorting(event) {
    this.sortBy = event.active;
    this.direction = event.direction;
    this.bindExpenses();
  }

  changePage(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.bindExpenses();
  }

  getImagePath(item: any) {
    console.log('item: ', item);
    return BaseApiService.baseApiUrl + item.imagePath;
  }

}
