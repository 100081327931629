import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Category, Product } from './models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

export class Data {
  constructor(public categories: Category[],
    public compareList: Product[],
    public wishList: Product[],
    public cartList: any[],
    public totalPrice: number,
    public totalCartCount: number,
    public unreadNotificationsCount: number,
    public unreadNotificationsCountPrevious: number,
    public title: string,
    public loggedIn: boolean) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [], // categories
    [], // compareList
    [],  // wishList
    [],  // cartList
    null, // totalPrice,
    0, // totalCartCount,
    0, // unreadNotificationCount,
    0,
    'JhelumCart Online Pvt. Ltd.', // Site title,
    false
  );
  // private isHomePageSubject = new Subject<boolean>();
  // getIsHomePageSubject = this.isHomePageSubject.asObservable();
  // setIsHomePageSubject(value: boolean) {
  //   this.isHomePageSubject.next(value);
  // }

  constructor(public snackBar: MatSnackBar, public cookieService: CookieService, private router: Router) { }

  public resetProductCartCount(product: Product) {
    product.cartCount = 0;
    const compareProduct = this.Data.compareList.filter(item => item.id === product.id)[0];
    if (compareProduct) {
      compareProduct.cartCount = 0;
    }
    const wishProduct = this.Data.wishList.filter(item => item.id === product.id)[0];
    if (wishProduct) {
      wishProduct.cartCount = 0;
    }
  }

  public addToCart(product: any, price: number, moq: number, image: string, quantity: number,
    selectedColor: string, selectedSize: string, selectedWeight: string) {
    let message;
    let status;

    if (localStorage.getItem('cart') == null || localStorage.getItem('cart') === '') {
      const item = {
        id: product.id,
        imagePath: image,
        name: product.name,
        specifications: product.specifications,
        color: selectedColor,
        size: selectedSize,
        weight: selectedWeight,
        price: price,
        moq: moq,
        quantity,
        total: parseFloat(price.toString()) * quantity,
        username: product.user.username,
        pinCode: product.user.pinCode
      };

      const items: any[] = [];
      items.push(item);
      localStorage.setItem('cart', encodeURIComponent(JSON.stringify(items)));

      this.Data.totalCartCount = items.length;
      this.Data.cartList = items;
      let totalPrice = 0.0;
      // tslint:disable-next-line: no-shadowed-variable
      items.forEach((item, index) => {
        totalPrice += item.total;
      });
      this.Data.totalPrice = totalPrice;

      message = 'The product ' + product.name + ' has been added to cart.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

    } else {
      const items: any[] = JSON.parse(decodeURIComponent(localStorage.getItem('cart')));
      const index = items.findIndex(d => d.id === product.id && d.size === selectedSize &&
        d.color === selectedColor && d.weight === selectedWeight);
      if (index >= 0) {
        items[index].quantity = parseInt(items[index].quantity, 0) + quantity;
        items[index].total = parseFloat(items[index].price) * parseInt(items[index].quantity, 0);
      } else {
        const obj = {
          id: product.id,
          imagePath: image,
          name: product.name,
          specifications: product.specifications,
          color: selectedColor,
          size: selectedSize,
          weight: selectedWeight,
          price: price,
          moq: moq,
          quantity,
          total: parseFloat(price.toString()) * quantity,
          username: product.user.username,
          pinCode: product.user.pinCode
        };
        items.push(obj);
      }

      localStorage.setItem('cart', encodeURIComponent(JSON.stringify(items)));

      this.Data.totalCartCount = items.length;
      this.Data.cartList = items;
      let totalPrice = 0.0;
      // tslint:disable-next-line: no-shadowed-variable
      items.forEach((item, index) => {
        totalPrice += item.total;
      });
      this.Data.totalPrice = totalPrice;

      message = 'The product ' + product.name + ' has been added to cart.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    }


  }

  public getCart() {
    if (localStorage.getItem('cart') != null && localStorage.getItem('cart') !== '') {
      this.Data.cartList = JSON.parse(decodeURIComponent(localStorage.getItem('cart')));
      this.Data.totalPrice = 0;
      this.Data.totalCartCount = this.Data.cartList.length;
      this.Data.cartList.forEach((item, index) => {
        this.Data.totalPrice += item.price * item.quantity;
      });
    }
  }

  removeCartItem(item) {
    const index = this.Data.cartList.findIndex(d => d.id === item.id &&
                  d.color === item.color && d.size === item.size && d.weight === item.weight);
    this.Data.cartList.splice(index, 1);
    if (this.Data.cartList.length === 0) {
      localStorage.setItem('cart', '');
    } else {
      localStorage.setItem('cart', encodeURIComponent(JSON.stringify(this.Data.cartList)));
    }

    this.Data.totalCartCount = this.Data.cartList.length;
    this.Data.totalPrice = 0;
    this.Data.cartList.forEach((item, index) => {
      this.Data.totalPrice += item.price * item.quantity;
    });

  }

  public getDeliveryMethods() {
    return [
      { value: 'COD', name: 'Cash on Delivery', desc: '' },
      { value: 'OnlinePayment', name: 'Online Payment', desc: '' }
    ];
  }

  isLoggedIn() {
    const token = localStorage.getItem('token');
    if (token == null || token === '' || token === undefined) {
      return false;
    }
    const currentDate = new Date();
    const tokenExpirationDate = new Date(JSON.parse(localStorage.getItem('token'))['.expires']);
    if (currentDate > tokenExpirationDate) {
      return false;
    }
    return true;
  }

  isAdminLoggedIn() {
    const token = localStorage.getItem('token');
    if (token == null || token === '' || token === undefined) {
      return false;
    }
    const currentDate = new Date();
    const tokenExpirationDate = new Date(JSON.parse(localStorage.getItem('token'))['.expires']);
    if (currentDate > tokenExpirationDate) {
      return false;
    }

    if (JSON.parse(localStorage.getItem('token')).role === 'Admin') {
      return true;
    }

    return false;
  }

  isLogisticPartnerLoggedIn() {
    const token = localStorage.getItem('token');
    if (token == null || token === '' || token === undefined) {
      return false;
    }
    const currentDate = new Date();
    const tokenExpirationDate = new Date(JSON.parse(localStorage.getItem('token'))['.expires']);
    if (currentDate > tokenExpirationDate) {
      return false;
    }

    if (JSON.parse(localStorage.getItem('token')).role === 'Seller') {
      return true;
    }

    return false;
  }

  isSellerLoggedIn() {
    const token = localStorage.getItem('token');
    if (token == null || token === '' || token === undefined) {
      return false;
    }
    const currentDate = new Date();
    const tokenExpirationDate = new Date(JSON.parse(localStorage.getItem('token'))['.expires']);
    if (currentDate > tokenExpirationDate) {
      return false;
    }

    if (JSON.parse(localStorage.getItem('token')).role === 'Seller') {
      return true;
    }

    return false;
  }

  logout() {
    localStorage.removeItem('token');
    this.Data.loggedIn = false;
    this.router.navigate(['/sign-in']);
  }
}
