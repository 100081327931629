import { StockService } from './../stock.service';
import { ProductsService } from './../products.service';
import { ProductAttribute } from './../models';
import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { Product } from '../models';
import { EventEmitter } from '@angular/core';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit, OnDestroy {

  @Input() product: Product;
  @Input() item: any;
  @Input() sizes: ProductAttribute[];
  @Input() colors: ProductAttribute[];
  @Input() weights: ProductAttribute[];
  @Input() selectedSize: string;
  @Input() selectedColor: string;
  @Input() selectedWeight: string;
  @Input() selectedMoq: number;
  @Input() price: number;
  @Input() image: string;
  @Input() type: string;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onOpenProductDialog = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() cartCountChange = new EventEmitter<number>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onQuantityChange = new EventEmitter<any>();
  public quantity = null;
  public align = 'center center';
  stockSub: Subscription = null;
  constructor(public appService: AppService,
    private stockService: StockService,
    public snackBar: MatSnackBar,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.layoutAlign();
    this.quantity = this.item == null || this.item === undefined ? 1 : this.item.quantity;
  }

  public layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    } else if (this.type === 'quickview') {
      this.align = 'end center';
    } else {
      this.align = 'center center';
    }
  }



  public increment() {
      this.quantity++;
          if (this.product) {
            const obj = {
              productId: this.product.id,
              quantity: this.quantity,
              total: this.quantity
            };
            this.changeQuantity(obj);
          } else {
            const obj = {
              productId: this.item.id,
              quantity: this.quantity,
              total: this.quantity * this.item.price
            };
            this.changeQuantity(obj);
          }
  }

  public decrement() {

    if (this.quantity > 1) {
      this.quantity--;
        if (this.product) {
          const obj = {
            productId: this.product.id,
            quantity: this.quantity,
            total: this.quantity
          };
        }
    }
  }

  public addToCompare(product: Product) {
    // this.appService.addToCompare(product);
    Swal.fire('Coming soon..', 'Coming soon...');
  }

  public addToWishList(product: Product) {
    Swal.fire('Coming soon..', 'Coming soon...');
    // this.appService.addToWishList(product);
  }

  public addToCart(product: Product) {
    // console.log(product)
    // let currentProduct = this.appService.Data.cartList.filter(item=>item.id == product.id)[0];
    // if(currentProduct){
    //   if((currentProduct.cartCount + this.count) <= this.product.availibilityCount){
    //     product.cartCount = currentProduct.cartCount + this.count;
    //   }
    //   else{
    //     this.snackBar.open('You can not add more items than available. In stock ' +
    // this.product.availibilityCount + ' items and you already added ' + currentProduct.cartCount +
    // ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    //     return false;
    //   }
    // }
    // else{
    //   product.cartCount = this.count;
    // }
    // this.appService.addToCart(product);

    if (this.colors !== null && this.colors !== undefined && this.colors.length > 0 && this.selectedColor == '') {
      this.snackBar.open('Plese select Color!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    if (this.sizes !== null && this.sizes !== undefined && this.sizes.length > 0 && this.selectedSize == '') {
      this.snackBar.open('Plese select Size!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    if (this.weights !== null && this.weights !== undefined && this.weights.length > 0 && this.selectedWeight == '') {
      this.snackBar.open('Plese select Weight!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    if (this.quantity < this.selectedMoq) {
      this.snackBar.open('Quantity should be at least ' + this.selectedMoq, 'x', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    if (this.stockSub != null) {
      this.stockSub.unsubscribe();
    }

    this.spinner.show();

    this.stockSub = this.stockService.getByParameters(this.product.id, this.quantity,
      this.selectedColor !== undefined ? this.selectedColor : '',
      this.selectedSize !== undefined ? this.selectedSize : '',
      this.selectedWeight !== undefined ? this.selectedWeight : '').subscribe(res => {
        this.spinner.hide();
        if (res != null && res.balance > 0) {
          this.appService.addToCart(this.product, this.price, this.selectedMoq, this.image, this.quantity,
            this.selectedColor === undefined || this.selectedColor == null ? '' :
              this.selectedColor,
            this.selectedSize === undefined || this.selectedSize == null ? '' :
              this.selectedSize,
            this.selectedWeight === undefined || this.selectedWeight == null ? '' :
              this.selectedWeight);
        } else {
          this.snackBar.open('Stock not available!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
      }, err => {
        this.spinner.hide();
        this.snackBar.open('Error occured. Please try again...', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      });

  }

  ngOnDestroy() {
    if (this.stockSub != null) {
      this.stockSub.unsubscribe();
    }
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

}
