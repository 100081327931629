import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { LogisticPartnerHomeComponent } from './logistic-partner-home/logistic-partner-home.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogisticPartnerRoutingModule } from './logistic-partner-routing.module';
import { LogisticPartnerOrdersComponent } from './logistic-partner-orders/logistic-partner-orders.component';
import { LogisticPartnerBestRouteComponent } from './logistic-partner-best-route/logistic-partner-best-route.component';
import { LogisticPartnerBuyerComponent } from './logistic-partner-buyer/logistic-partner-buyer.component';
import { LogisticPartnerDefaultComponent } from './logistic-partner-default/logistic-partner-default.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LogisticPartnerDashboardComponent } from './logistic-partner-dashboard/logistic-partner-dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeliveryBoyRegisterComponent } from '../delivery-boy-register/delivery-boy-register.component';
import { CustomOrdersComponent } from './custom-orders/custom-orders.component';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { RequestOrdersComponent } from './request-orders/request-orders.component';
import { RequestOrderDetailsComponent } from './request-order-details/request-order-details.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ExpensesComponent } from './expenses/expenses.component';
import { AddExpenseComponent } from './add-expense/add-expense.component';
import { EditExpenseComponent } from './edit-expense/edit-expense.component';
import { CashcollectionComponent } from './cashcollection/cashcollection.component';



@NgModule({
  declarations: [ 
    LogisticPartnerHomeComponent, LogisticPartnerOrdersComponent, LogisticPartnerBestRouteComponent, 
      LogisticPartnerBuyerComponent, LogisticPartnerDefaultComponent, OrderDetailsComponent,
      NotificationsComponent, LogisticPartnerDashboardComponent, ProfileComponent, ProfileEditComponent, CustomOrdersComponent, CustomOrderDetailsComponent, RequestOrdersComponent, RequestOrderDetailsComponent, ExpensesComponent, AddExpenseComponent, EditExpenseComponent, CashcollectionComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    LogisticPartnerRoutingModule,
    ColorPickerModule,
    CKEditorModule,
    FormsModule,
    FlexLayoutModule,
    NgxPaginationModule
  ],
  providers: [
    DatePipe
  ]
})
export class LogisticPartnerModule { }
