import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from './../contact.service';
import { AppService } from './../app.service';
import { Title } from '@angular/platform-browser';
import { SiteSetting, Contact } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteSettingsService } from 'src/app/site-settings.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../theme/utils/app-validators';
import { BaseApiService } from '../base-api.service';

@Component({
  selector: 'app-our-pricing',
  templateUrl: './our-pricing.component.html',
  styleUrls: ['./our-pricing.component.scss']
})
export class OurPricingComponent implements OnInit {
  siteSetting: SiteSetting = null;
  baseApiUrl = BaseApiService.baseApiUrl;

  monthly = [
    {service: "Photography", basic: "Y(Upto 100products/Month)", advanced: "Y(Upto 300 Products/Month)"},
    {service: "Graphic Design", basic: "Y(Upto 100products/Month)",	advanced: "Y(Upto 300 Products/Month)"},
    {service: "Content Writing",	basic: "Y(Upto 100products/Month)", advanced: "Y(Upto 300 Products/Month)"},
    {service: "Seller Dashboard Management", basic: "Y", advanced: "Y"},
    {service: "Billing & Inventory mgt. Software", basic: "N", advanced: "Y"},
    {service: "Social Media Posts",	basic: "Y(4 Posts/Month)",	 advanced: "Y(12 Posts/Month)"},
    {service: "Logistics", basic: "Y", advanced: "Y"}
  ];

  fees = [
    {key:"Accessories - Electronics, PC and Wireless", value:"9.90%"},
    {key:"Apparel - Accessories", value:"10.20%"},
    {key:"Apparel", value:"10.20%"},
    {key:"Baby Products", value:"3.60%"},
    {key:"Backpacks", value:"4.20%"},
    {key:"Bean Bags and Inflatables", value:"6%"},
    {key:"Beauty - Fragrance", value:"7.50%"},
    {key:"Beauty Products", value:"2.40%"},
    {key:"BICYCLE", value:"3.60%"},
    {key:"Books (upto INR 1000)", value:"4.20%"},
    {key:"Books (more than INR 1000)", value:"7.80%"},
    {key:"Cables - Electronics, PC, Wireless", value:"12%"},
    {key:"Camera Accessories", value:"6.60%"},
    {key:"Camera Lenses", value:"4.20%"},
    {key:"Camera and Camcorder", value: "3%"},
    {key:"Car Electronics Devices", value: "3.30%"},
    {key:"Carpets & Aprons", value:"6.60%"},
    {key:"Cases/Covers/Skins/Screen Guards", value: "14.40%" },
    {key:"Clocks", value:"4.80%"},
    {key:"Craft Materials", value:"3.30%"},
    {key:"Desktops", value:"3.90%"},
    {key:"Electronic Devices (except TV, Camera & Camcorder, Camera Lenses and Accessories, GPS Devices, Speakers)", value:"5.10%"},
    {key:"Eyewear", value:"5.10%"},
    {key:"Fashion Jewellery", value:"12.90%"},
    {key:"Fine Art", value:"12%"},
    {key:"Flip Flops & Slippers", value:"6%"},
    {key:"Furniture", value:"6%"},
    {key:"GPS Devices", value:"8.10%"},
    {key:"Grocery and Gourmet", value:"2.40%"},
    {key:"Gym Equipments", value:"5.40%"},
    {key:"HPC - Household Supplies, Personal Care & Ayurveda", value:"2.40%"},
    {key:"HPC - Medical Equipment & Contact Lens", value:"3.90%"},
    {key:"HPC - Nutrition", value:"5.40%"},
    {key:"Handbags", value:"6%"},
    {key:"Home - Other Subcategories", value:"9.90%"},
    {key:"Hard Disks", value:"5.10%"},
    {key:"Headsets, Headphones and Earphones", value:"8.40%"},
    {key:"HPC (Health and Personal Care) - Other Subcategories", value:"6.60%"},
    {key:"Home - Candles", value:"6.30%"},
    {key:"Home - Bedsheets, Blankets & Covers", value:"5.40%"},
    {key:"Home Furnishing ", value:"6.60%"},
    {key:"Home Improvement (excl. Accessories)", value:"4.80%"},
    {key:"Home Improvement - Accessories ", value:"6.90%"},
    {key:"Home Improvement - Ladders, Kitchen, Bath Fixtures & Home Security Systems", value:"3%"},
    {key:"Home Storage (more than INR 250)", value:"7.20%"},
    {key:"Indoor Lighting (excluding LED bulbs and Battens)", value:"8.10%"},
    {key:"Industrial Supplies ", value:"3%"},
    {key:"Keyboards and Mouse", value:"9.60%"},
    {key:"Kitchen - Non Appliances", value:"6.90%"},
    {key:"Kitchen - Glassware & Ceramicware", value:"4.20%"},
    {key:"Kitchen - Gas Stoves & Pressure Cookers", value:"4.20%"},
    {key:"LED Bulbs and Battens", value:"4.20%"},
    {key:"Laptop Bags & Sleeves", value:"4.20%"},
    {key:"Laptop Battery", value:"8.40%"},
    {key:"Laptops", value:"3%"},
    {key:"Large Appliances (excl. Accessories and Chimneys)", value:"2.70%"},
    {key:"Large Appliances - Accessories", value:"6.60%"},
    {key:"Large Appliances - Chimneys", value:"3.90%"},
    {key:"Lawn & Garden - Others", value:"3%"},
    {key:"Lawn and Garden - Chemical Pest Control", value:"2.40%"},
    {key:"Lawn and Garden - Solar Devices", value:"3%"},
    {key:"Luggage - Suitcase & Trolleys", value:"4.20%"},
    {key:"Luggage - Travel Accessories", value:"5.40%"},
    {key:"Memory Cards", value:"7.20%"},
    {key:"Mobile Phones & Tablets (including Graphic Tablets)", value:"3%"},
    {key:"Modems & Networking Devices", value:"8.40%"},
    {key:"Monitors", value:"2.70%"},
    {key:"Musical Instruments (excluding Keyboards)", value:"4.50%"},
    {key:"Musical Instruments - Keyboards", value:"3%"},
    {key:"Office Products", value:"4.20%"},
    {key:"Outdoor Equipments (Pumps, Generators, Grilling, Agricultural Machinery, Saws, Tillers, Pressure Washers, Lawn Mowers & String Trimmers)", value:"3%"},
    {key:"PC Components (RAM, Motherboards)", value:"4.20%"},
    {key:"Pantry", value:"7.80%"},
    {key:"Personal Care Appliances ", value:"5.70%"},
    {key:"Pet Products", value:"6.60%"},
    {key:"Power Banks", value:"10.80%"},
    {key:"Scanners and Printers", value:"4.80%"},
    {key:"Shoes", value:"9.30%"},
    {key:"Shoes - Kids Footwear", value:"6.30%"},
    {key:"Shoes - Sandals & Floaters", value:"6.30%"},
    {key:"Silver Jewellery", value:"4.80%"},
    {key:"Small Appliances", value:"3.30%"},
    {key:"Speakers", value:"6.60%"},
    {key:"Sports - Cricket & Badminton Equipments", value:"3.60%"},
    {key:"Sports - Football, Volleyball, Basketball, Throwball", value:"3.60%"},
    {key:"Sports - Tennis, Table Tennis & Squash", value:"3.60%"},
    {key:"Sports - Swimming", value:"3.60%"},
    {key:"Sports - Others ", value:"5.40%"},
    {key:"Television", value:"3.60%"},
    {key:"Toys", value:"5.70%"},
    {key:"USB Flash Drives (Pen Drives)", value:"9.60%"},
    {key:"Video Games - Accessories", value:"6%"},
    {key:"Video Games - Consoles", value:"4.20%"},
    {key:"Video Games", value:"4.20%"},
    {key:"Wallets", value:"6.30%"},
    {key:"Wall Art (Paintings, Posters, Decorative Stickers and Art Prints)", value:"7.50%"},
    {key:"Watches", value:"8.10%"}
  ];  
  constructor(private titleService: Title,
              private spinner: NgxSpinnerService,
              private siteSettingsService: SiteSettingsService,
              private appService: AppService) { }

  ngOnInit() {
    this.titleService.setTitle('Pricing - ' + this.appService.Data.title);
    this.getSiteSettings();
  }

  getSiteSettings() {
    this.spinner.show();
    this.siteSettingsService.getSettings().subscribe(res => {
      this.siteSetting = res;
      // this.titleService.setTitle('Home - ' + res.siteName);
      this.appService.Data.title = res.siteName;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

}
