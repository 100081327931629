import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logistic-partner-best-route',
  templateUrl: './logistic-partner-best-route.component.html',
  styleUrls: ['./logistic-partner-best-route.component.scss']
})
export class LogisticPartnerBestRouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
