export class Slider {
  id?: number;
  imagePath: string;
  mobileImagePath: string;
  tabImagePath?: string;
  displayOrder: number;
  bigLabel?: string;
  smallLabel?: string;
}

export class SellerSlider {
  id?: number;
  username: string;
  imagePath: string;
  mobileImagePath: string;
  tabImagePath?: string;
  displayOrder: number;
  routerLink: string;
}

export class Banner {
  id?: number;
  imagePath: string;
  routerLink: string;
  bannerNo: number;
  bigText?: string;
  smallText?: string;
}

export class TopCategory {
  id?: number;
  menuID: number;
  categoryID: number;
  subcategoryID: number;
  subCategory?: SubCategory;
  imagePath: string;
  displayName: string;
  displayOrder: number;
}

export class Color {
  id?: number;
  colorName: string;
  colorValue: string;
}

export class SubCategory {
  id?: number;
  categoryID: number;
  subcategoryName: string;
  subcategoryOrder: number;
  imagePath: string;
}

export class Category {
  id?: number;
  menuID: number;
  categoryName: string;
  categoryOrder: number;
  imagePath: string;
  subCategories?: SubCategory[];
}

export class Menu {
  id?: number;
  menuName: string;
  menuOrder: number;
  categories?: Category[];
}

export class Stock {
  id?: number;
  productID: number;
  color: string;
  size: string;
  weight: string;
  brand: string;
  physicalStock?: number;
  toBeDispatched?: number;
  balance?: number;
}

export class BankDetails {
  id?: number;
  name: string;
  accountNumber: string;
  ifscCode: string;
  username: string;
}

export class HomePageHeading {
  id?: number;
  headingName: string;
  headingOrder: number;
  homePageProducts?: HomePageProduct[];
}

export class HomePageProduct {
  id?: number;
  headingID: number;
  productID: number;
  productOrder: number;
  product?: Product;
}

export class Product {
  id?: number;
  name: string;
  keywords: string;
  commissionPercentage: number;
  itemDescription?: string;
  shortDescription: string;
  specifications: string;
  description: string;
  username: string;
  isActive: boolean;
  codAvailable: boolean;
  onlinePaymentAvailable: boolean;
  barcode?: string;
  categoryID: number;
  subcategoryID: number;
  addedOn?: Date;
  modifiedOn?: Date;
  addedBy?: string;
  modifiedBy?: string;
  cartCount?: number;
  productImages?: ProductImage[];
  user?: User;
}

export class ProductAttribute {
  id?: number;
  productID: number;
  color?: string;
  colorName?: string;
  size?: string;
  dimensions?: string;
  weight?: string;
  brand?: string;
  physicalStock: number;
  toBeDispatched?: number;
  balance?: number;
  price: number;
  salePrice: number;
  bargainingPrice?: number;
  moq: number;
  addedOn?: Date;
  addedBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
}

export class OTPResult{
  Status: string;
  Details: string;
}

export class SiteSetting {
  id?: number;
  headerContactNo: string;
  logoPath: string;
  siteName: string;
  address: string;
  mapHtml: string;
  phoneNos: string;
  emails: string;
  about: string;
  aboutUs: string;
  cancellationRefundPolicy: string;
  privacyPolicy: string;
  pricing: string;
  terms: string;
}

export class User {
  id?: any;
  name: string;
  username: string;
  role?: string;
  email: string;
  mobile: string;
  alternateMobile?: string;
  jurisdiction?: string;
  address?: string;
  password?: string;
  countryID?: number;
  stateID?: number;
  city?: string;
  pinCode?: string;
  businessName?: string;
  businessAddress?: string;
  businessLogoPath1?: string;
  businessLogoPath2?: string;
  businessLogoPath3?: string;
  businessLogoPath4?: string;
  registrationDocumentImagePath?: string;
  aadharCardImagePath?: string;
  panCardImagePath?: string;
  licenseImagePath?: string;
  qrCode?: string;
  about?: string;
  refundWithin?: number;
  sellerType?: string;
  latitude?: string;
  longitude?: string;
  gstRegistrationNumber?: string;
  isActive?: boolean;
  addedOn?: Date;
  modifiedOn?: Date;
  modifiedBy?: string;
}

export class UserDTO {
  user: User;
  otp: string;
  sessionID: string;
}


export interface TokenAuth {
    username: string;
    password: string;
    grant_type: string;
}

export class Country {
  id?: number;
  name: string;
  sortName: string;
  phoneCode: string;
}

export class State {
  id?: number;
  name: string;
  countryID: number;
}

export class ProductImage {
  id?: number;
  productID: number;
  color?: string;
  colorName?: string;
  imagePath: string;
}

export class Order {
  id?: number;
  paymentOrderID?: number
  mobile: string;
  customerName: string;
  customerEmail: string;
  customerMobile: string;
  customerCountry: string;
  customerState: string;
  customerCity: string;
  customerAddress: string;
  pinCode: string;
  orderDate?: Date;
  orderStatus: string;
  subTotal: number;
  discount: number;
  shippingCharges: number;
  total: number;
  paymentMethod: string;
  username: string;
  logisticPartnerUsername?: string;
  sellerUsername?: string;
  sellerDispatchCode?: string;
  customerDeliveryCode?: string;
  returnInitiated?: boolean;
}

export class CustomOrder {
  id?: number;
  mobile: string;
  customerName: string;
  customerEmail: string;
  customerMobile: string;
  customerCountry: string;
  customerState: string;
  customerCity: string;
  customerAddress: string;
  pinCode: string;
  orderDate?: Date;
  orderStatus: string;
  subTotal: number;
  discount: number;
  shippingCharges: number;
  total: number;
  paymentMethod: string;
  username: string;
  logisticPartnerUsername?: string;
  sellerUsername?: string;
  sellerDispatchCode?: string;
  customerDeliveryCode?: string;
  returnInitiated?: boolean;
  seller?: User;
  pickupLocation?: string;
  pickupDateTime?: Date;
  locationLink?: string;
}

export class RequestOrder {
  id?: number;
  customerName: string;
  customerEmail: string;
  customerMobile: string;
  customerCountry: string;
  customerState: string;
  customerCity: string;
  customerAddress: string;
  pinCode: string;
  orderDate?: Date;
  orderStatus: string;
  subTotal: number;
  discount: number;
  shippingCharges: number;
  returnShippingCharges?: number;
  total: number;
  paymentMethod?: string;
  username: string;
  logisticPartnerUsername?: string;
  sellerUsername?: string;
  sellerDispatchCode?: string;
  customerDeliveryCode?: string;
  returnInitiated?: boolean;
  pickupLocation?: string;
  pickupDateTime?: string;
  seller?: User;
  paymentStatus?: string;
  deliveryMode?: string;
  categories?: string;
  orderNote?: string;
  orderDetails?: RequestOrderDetails[];
  orderHistories?: RequestOrderHistory[];
}

export class OrderDetails {
  id?: number;
  orderID: number;
  productID: number;
  productName: string;
  product?: Product;
  specifications: string;
  color: string;
  size: string;
  weight: string;
  quantity: number;
  price: number;
  total: number;
  username: string;
  pinCode: string;
}

export class CustomOrderDetails {
  id?: number;
  orderID: number;
  productID: number;
  productName: string;
  product?: Product;
  category?: string;
  color: string;
  size: string;
  weight: string;
  quantity: number;
  price: number;
  total: number;
}

export class RequestOrderDetails {
  id?: number;
  orderID: number;
  productName: string;
  category?: string;
  quantity: number;
  price: number;
  total: number;
  netWeight?: number;
  totalNetWeight?: number;
  availability?: string;
}

export class ShippingPincode {
  id?: number;
  fromPincode: string;
  toPinCode: string;
  shippingCharges: number;
  deliveryTime: string;
}

export class OrderHistory {
  id?: number;
  orderID: number;
  dateModified?: Date;
  comments: string;
}

export class CustomOrderHistory {
  id?: number;
  orderID: number;
  dateModified?: Date;
  comments: string;
}

export class RequestOrderHistory {
  id?: number;
  orderID: number;
  dateModified?: Date;
  comments: string;
}

export class OrderMasterObject {
  order?: Order;
  orderItems?: OrderDetails[];
  orderHistory?: OrderHistory;
}

export class RequestOrderMasterObject {
  order?: RequestOrder;
  orderItems?: RequestOrderDetails[];
  orderHistory?: RequestOrderHistory;
  from?: string;
  websiteVersion?: string;
  appVersion?: string;
}

export class PaymentOrder {
  id?: number;
  username?: string;
  amount?: number;
  status?: string;
  addedOn?: Date;
  modifiedOn?: Date;
}

export class Address {
  id?: number;
  username: string;
  name: string;
  email: string;
  mobile: string;
  countryID: number;
  stateID: number;
  city: string;
  streetAddress: string;
  zipCode: string;
  isDefault: boolean;
}

export class Contact {
  id?: number;
  name: string;
  email: string;
  mobile: string;
  message: string;
  dateAdded?: Date;
}

export interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  username: string;
  role: string;
  '.issued': string;
  '.expires': string;

}

export class AppNotification {
  id?: string;
  notificationTitle: string;
  notificationText: string;
  routerLink: string;
  username: string;
  read: boolean;
}

export class SidenavMenu {
  constructor(public id: number,
              public title: string,
              public routerLink: string,
              public href: string,
              public target: string,
              public hasSubMenu: boolean,
              public parentId: number) { }
}


