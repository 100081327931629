import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { UsersService } from '../users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { BaseApiService } from '../base-api.service';
import { SellerSlider } from '../models';

@Component({
  selector: 'app-seller-carousel',
  templateUrl: './seller-carousel.component.html',
  styleUrls: ['./seller-carousel.component.scss']
})
export class SellerCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  slides: SellerSlider[] = [];
  usersSub: Subscription = null;

  baseApiUrl = BaseApiService.baseApiUrl;

  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.spinner.show();
    this.usersSub = this.usersService.getSliders(this.activatedRoute.snapshot.params.name).subscribe(res => {
      this.slides = res;
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: true,
      preloadImages: true,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: 'slide'
    };
  }

  ngOnDestroy() {
    if (this.usersSub != null) {
      this.usersSub.unsubscribe();
    }
  }

  slideChanged(event) {
    const mySwiper = (document.querySelector('.swiper-container') as any).swiper;
    if (mySwiper.activeIndex === this.slides.length - 1) {
      setTimeout(() => {
        mySwiper.slideTo(0);
      }, 3000);
    }
  }

}
