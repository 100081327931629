import { Menu } from './../models';
import { MenuService } from './../menu.service';
import { Title } from '@angular/platform-browser';
import { BaseApiService } from './../base-api.service';
import { CookieService } from 'ngx-cookie-service';
import { SiteSetting } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteSettingsService } from 'src/app/site-settings.service';
import { Component, OnInit, AfterViewInit, ViewChild, HostListener, ChangeDetectorRef, OnChanges, ElementRef, OnDestroy, AfterContentInit } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { Settings, AppSettings } from '../app-settings';
import { Category } from '../models';
import { CategoryService } from '../category.service';
import { AppService } from '../app.service';
import { SidenavMenuService, sidenavMenuItems } from '../sidenav-menu.service';
import { ProductsService } from '../products.service';
import { Subscription } from 'rxjs';
import { SearchDialogComponent } from '../search-dialog/search-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '../users.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ SidenavMenuService ]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
  public showBackToTop = false;
  public categories: Category[];
  public menus: Menu[] = [];
  public category: Category;
  public sidenavMenuItems: Array<any> = [];
 
  baseApiUrl = BaseApiService.baseApiUrl;
  @ViewChild('sidenav', { static: true }) sidenav: any;

  query = '';
  searchByOptions = [ {value:'product', label: 'Product'} , {value:'seller', label: 'Seller'}];
  selectedSerachBy = this.searchByOptions[0].value;
  location = '';
  placeholder = 'Product';
  options = [];
  cartItems: any[];
  totalPrice = 0;
  cartCount = 0;
  public settings: Settings;
  siteSettings: SiteSetting = null;
  categoriesSub: Subscription = null;
  menusSub: Subscription = null;
  lastScrollTop = 0;
  hideHeaders = false;
  name: string;
  // isHomePageSub: Subscription = null;
  // isHomePage = false;
  constructor(public appSettings: AppSettings,
              public appService: AppService,
              public sidenavMenuService: SidenavMenuService,
              public categoryService: CategoryService,
              private siteSettingsService: SiteSettingsService,
              private menuService: MenuService,
              public router: Router,
              private cookieService: CookieService,
              private spinner: NgxSpinnerService,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog,
              private productService: ProductsService,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              private titleService: Title) {
    this.settings = this.appSettings.settings;
  }


  // getCartCount(count: number) {
  //   this.cartCount = count;
  // }

  // getCartItems(items: any[]) {
  //   this.cartItems = items;
  //   this.cartItems.forEach(function(item, index) {
  //     this.totalPrice += item.quantity * item.price;
  //   });
  // }

  gotoAccountPage() {
    const role = JSON.parse(localStorage.getItem('token')).role;
    if (role === 'Admin') {
      this.router.navigate(['/admin']);
    } else if (role === 'Seller') {
      this.router.navigate(['/seller']);
    } else if (role === 'LogisticPartner') {
      this.router.navigate(['/logisticpartner']);
    } else {
      this.router.navigate(['/account']);
    }
  }

  onSearchByChanged (val) {
   
    if (val === 'seller') {
      this.placeholder = 'Seller';
    } else {
      this.placeholder = 'Product';
    }
  }

  showSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      panelClass: 'search-dialog',
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(item => {
      if (item) {
        this.router.navigate(['/products', item.id, item.name]);
      }
    });
  }

  

 
  ngOnInit() {

    // isHomePage
    // console.log(location.hash);

    this.usersService.getScrollTopSubject.subscribe( val => {
      if (val) {
        this.scrollToProducts();
      }
    });

    this.getCategories();
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems(res);
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });

    this.getSiteSettings();


    this.appService.Data.loggedIn = this.appService.isLoggedIn();

    const token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      const username = JSON.parse(localStorage.getItem('token')).username;
      this.spinner.show();
      this.usersService.getUserByUsername(username).subscribe(res => {
        this.name = res.name;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
      });
    }
  }

  getSiteSettings() {
    this.spinner.show();
    this.siteSettingsService.getSettings().subscribe(res => {
      this.siteSettings = res;
      // this.titleService.setTitle('Home - ' + res.siteName);
      this.appService.Data.title = res.siteName;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  removeItem(id) {
    this.appService.removeCartItem(id);

  }

  // onActivate(componentReference) {
  //   if (componentReference == null || componentReference === undefined) {
  //     return;
  //   }
  //   componentReference.cartCountChange.subscribe(data => {
  //     this.appService.Data.totalCartCount = data;
  //   });
  //   componentReference.cartItemsChange.subscribe(data => {
  //     this.appService.Data.cartList = data;
  //     this.appService.Data.cartList.forEach( (item, index) => {
  //       this.appService.Data.totalPrice += item.price * item.quantity;
  //     });
  //   });
  // }

  public getCategories() {
    this.categoriesSub = this.categoryService.getCategories().subscribe(data => {
      // this.categories = data;
      // this.category = data[0];
      this.appService.Data.categories = data;
    });
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(category => category.categoryName === event.target.innerText)[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
      const index: number = this.appService.Data.cartList.indexOf(product);
      if (index !== -1) {
          this.appService.Data.cartList.splice(index, 1);
          this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice * product.cartCount;
          this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
          this.appService.resetProductCartCount(product);
      }
  }

  public clear() {
    this.cookieService.set('cart', '');
    this.appService.Data.cartList = [];
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public doSearch() {
    const navigationExtras: NavigationExtras = {
      queryParams : {'location' : this.location, 'query' : this.query}
    }
    if (this.selectedSerachBy === 'seller') {
      this.options = [];
      this.router.navigate(['/search-seller'], navigationExtras);
      
    } else {
      this.options = [];
      this.router.navigate(['/search-product'], navigationExtras);
    
    }
    
  }

  doAutocomplete(event) {
    this.options = null;
    this.options = [];
    console.log('Search type: ', this.selectedSerachBy);
    if (this.selectedSerachBy.toLowerCase() === 'product'){
      this.productService.getAutocompleteList(this.query).subscribe(res => {
        this.options = res;      
        // res.forEach(item => {
        //   this.options.push(item);
        // });
      });
    } else {
      this.usersService.getAutocompleteList(this.query).subscribe(res => {
        this.options = res;      
        // res.forEach(item => {
        //   this.options.push(item);
        // });
      });
    }   
  }

  gotoCheckout() {
    if (!this.appService.isLoggedIn()) {
      this.router.navigate(['/sign-in']);
    } else {
      let grandTotal = 0;
      this.appService.Data.cartList.forEach(item => {
        grandTotal += item.quantity * item.price;
      });
      // if (grandTotal < 300) {
      //   this.snackBar.open('Cart value should not be less than 300!', 'x', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      //   return;
      // }
      this.router.navigate(['/checkout']);
    }
  }

  public scrollToProducts() {
    // const scrollDuration = 200;
    // const scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    const scrollInterval = setTimeout(() => {
      if (window.pageYOffset != 0) {
        //  window.scrollBy(0, scrollStep);
        // console.log(document.body.scrollHeight);


        if (Number(window.innerWidth) <= 768) {
          // alert('window.innerWidth '+ window.innerWidth);
            window.scrollTo(0, 250);
        } else {
          if (document.body.scrollHeight > 1500 && document.body.scrollHeight < 2100) {
            window.scrollTo(0, 380);
          }
        }
         
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);

  }


  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
         window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => { window.scrollTo(0, 0); });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    // console.log('scolling...');

    var st = window.pageYOffset || document.documentElement.scrollTop; 
    if (st > this.lastScrollTop){
       // downscroll code
      //  console.log('scolling down...');
      this.hideHeaders = true;
    } else {
      // console.log('scolling up...');
      this.hideHeaders = false;
       // upscroll code
    }
    this.lastScrollTop = st <= 0 ? 0 : st; 

    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterContentInit() {
    // this.isHomePageSub = this.appService.getIsHomePageSubject.subscribe( isHomePage => {
    //    this.isHomePage = isHomePage;
    //    this.cdr.detectChanges();
    // } );
  }
  ngAfterViewInit() {
    this.appService.getCart();



    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems(this.menus));

    this.cdr.detectChanges();
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  ngOnDestroy() {
    // console.log('destroying');

    // if (this.isHomePageSub != null) {
    //   this.isHomePageSub.unsubscribe();
    // }
    if (this.menusSub != null) {
      this.menusSub.unsubscribe();
    }
    if (this.categoriesSub != null) {
      this.categoriesSub.unsubscribe();
    }
  }


}
