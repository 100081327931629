import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from './../contact.service';
import { AppService } from './../app.service';
import { Title } from '@angular/platform-browser';
import { SiteSetting, Contact } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteSettingsService } from 'src/app/site-settings.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../theme/utils/app-validators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  siteSetting: SiteSetting = null;

  constructor(public formBuilder: FormBuilder, private siteSettingsService: SiteSettingsService,
              private spinner: NgxSpinnerService, private titleService: Title,
              private appService: AppService, private contactService: ContactService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });

    this.titleService.setTitle('Contact - ' + this.appService.Data.title);

    this.spinner.show();
    this.siteSettingsService.getSettings().subscribe(res => {
      this.siteSetting = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });

  }

  public onContactFormSubmit(values: any): void {
    this.spinner.show();
    const contact: Contact = {
      name: this.contactForm.value.name,
      email: this.contactForm.value.email,
      mobile: this.contactForm.value.phone,
      message: this.contactForm.value.message
    };
    this.contactService.addContact(contact).subscribe(res => {
      this.spinner.hide();
      if (res) {
        this.snackBar.open('Thanks for contacting us. We will get back to you soon...', 'x',
                           {panelClass: 'success', verticalPosition: 'top', duration: 3000});
        this.contactForm.reset();
      } else {
        this.snackBar.open('Error occured. Please try again...', 'x',
                           {panelClass: 'error', verticalPosition: 'top', duration: 3000});
      }
    }, err => {
      this.spinner.hide();
      this.snackBar.open('Error occured. Please try again...', 'x',
                           {panelClass: 'error', verticalPosition: 'top', duration: 3000});
    });
  }

}
