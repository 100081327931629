import { BaseApiService } from './base-api.service';
import { User, TokenAuth, TokenResponse, OTPResult, SellerSlider, Slider, UserDTO } from './models';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private scrollTopSubject = new Subject<boolean>();
  
  getScrollTopSubject = this.scrollTopSubject.asObservable();
  setScrollTopSubject(value: boolean) {
    this.scrollTopSubject.next(value);
  }
  constructor(private http: HttpClient) { }

  verifyCaptcha(token: string, secretKey: string): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/User/VerifyCaptcha?token=' + token +
      '&secretKey=' + secretKey;

    return this.http.post<boolean>(url, null);
  }

  // adds the slider to the database
  addSlider(slider: SellerSlider): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/User/AddSlider';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});


    return this.http.post<boolean>(url, slider, {headers});
  }

  // updates the slider
  updateSlider(slider: SellerSlider): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/User/UpdateSlider';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<boolean>(url, slider, {headers});
  }

  // delete a slider
  deleteSlider(id: number): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/User/DeleteSlider/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<boolean>(url, {headers});
  }

  // gets all sliders from api
  getSliders(username): Observable<SellerSlider[]> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetSliders?username=' + username;
    return this.http.get<SellerSlider[]>(url);
  }

  // get SellerSlider by id
  getSlider(id: number): Observable<SellerSlider> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetSlider/' + id;
    return this.http.get<SellerSlider>(url);
  }

  //send OTP using mobile number entered
  sendOTP(mobileNumber: string): Observable<OTPResult> {
    var url = BaseApiService.baseApiUrl + "api/User/SendOTP?mobileNo=" + mobileNumber;



    return this.http.get<OTPResult>(url);
  }

  //verify OTP sent from api
  verifyOTP(otp, sessionID): Observable<OTPResult> {
    var url = BaseApiService.baseApiUrl + "api/User/VerifyOTP?otp=" + otp + "&sessionID=" + sessionID;



    return this.http.get<OTPResult>(url);
  }

  // upload business slider image
  uploadBusinessSliderImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/User/UploadBusinessLogo';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    }
    let token = JSON.parse(localStorage.getItem('token'));
    formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // upload business slider image
  uploadBusinessDocument(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/User/UploadBusinessDocument';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    }
    let token = JSON.parse(localStorage.getItem('token'));
    formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // upload logistic partner document
  uploadLogisticPartnerDocument(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/User/UploadLogisticPartnerDocument';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    }
    let token = JSON.parse(localStorage.getItem('token'));
    formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }


  addUser(userDTO: UserDTO): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/Post';


    return this.http.post<any>(url, userDTO);
  }

  sendPasswordResetLink(email: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/SendPasswordResetLink?email=' + email;


    return this.http.post<any>(url, null);
  }

  resetPassword(email: string, password: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/ResetPassword?email=' + email +
    '&password=' + password;


    return this.http.post<any>(url, null);
  }

  updateUser(user: User): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/Put';
    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, user, { headers });
  }

  rateSeller(sellerUsername: string, rating: number, review: string, updateReview: boolean): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/RateSeller';
    const token = JSON.parse(localStorage.getItem('token')).access_token;
    const username = JSON.parse(localStorage.getItem('token')).username;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const ratingData = {
      username,
      sellerUsername,
      rating,
      review,
      updateReview
    };

    return this.http.post<any>(url, ratingData, { headers });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/User/ChangePassword?username=' + username +
      '&oldPassword=' + oldPassword + '&newPassword=' + newPassword;
    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, null, { headers });
  }

  // authenticates a user and gets access token back
  login(auth: TokenAuth): Observable<TokenResponse> {
    const url = BaseApiService.baseApiUrl + 'api/token';
    // var data = {
    //   username: auth.username,
    //   password: auth.password,
    //   grant_type: auth.grant_type
    // };

    const data = 'username=' + auth.username + '&password=' +
      auth.password + '&grant_type=password';

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });


    return this.http.post<TokenResponse>(url, data, { headers });
  }

  getUserByUsername(username: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetUserByUsername?username=' + username;

    return this.http.get<any>(url);
  }

  getUserByMobile(mobile: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetUserByMobile?mobile=' + mobile;

    return this.http.get<any>(url);
  }

  getSellers(businessName: string, mobile: string, pinCode: string, pageNumber: number,
    pageSize: number, sortBy: string, direction: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetSellers?businessName=' + businessName +
      '&mobile=' + mobile + '&pinCode=' + pinCode + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize + '&sortBy=' + sortBy +
      '&direction=' + direction;

    return this.http.get<any>(url);
  }

  getSellerRatingsReviews(sellerUsername: string, pageNumber: number, pageSize: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetSellerRatingsReviews?sellerUsername=' +
      sellerUsername + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize;

    return this.http.get<any>(url);
  }

  getLogisticPartners(): Observable<User[]> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetLogisticPartners';

    return this.http.get<User[]>(url);
  }

  getLogisticPartnersByJurisdiction(jurisdiction: string): Observable<User[]> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetLogisticPartnersByJurisdiction?jurisdiction=' +
      jurisdiction;

    return this.http.get<User[]>(url);
  }

  getSellersBySearch(location: string, query: string, pageNumber: number, pageSize: number, sortBy: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetSellersBySearch?location=' + location +
      '&query=' + query + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&sortBy=' + sortBy;

    return this.http.get<any>(url);
  }

  generateQrCode(user: User): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/User/GenerateQrCode';

    return this.http.put<any>(url, user);
  }

  public getAutocompleteList(query: string): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/User/GetAutoCompleteList?search=' + query;

    return this.http.get<any[]>(url);

  }
}
