import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  public static baseApiUrl = 'http://localhost:57861/';
  public static baseFrontEndUrl = 'https://jhelumcart.com/';
  // public static baseApiUrl = 'http://api.gokec.in/';

  constructor() {
   }
}
