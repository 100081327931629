import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { MenuService } from '../menu.service';
import { Menu } from '../models';
import { SidenavMenuService } from '../sidenav-menu.service';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './app-sidenav-menu.component.html',
  styleUrls: ['./app-sidenav-menu.component.scss'],
  providers: [ SidenavMenuService ]
})
export class AppSidenavMenuComponent implements OnInit {
  baseApiUrl = BaseApiService.baseApiUrl;
  // @Input() menuItems;
  // @Input() menuParentId;
  // parentMenu: Array<any>;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  


  menus: Menu[] = [];
  categories: any[] = [];
  menuSub: Subscription;
  // @ViewChild('menuTrigger') menuTrigger: ElementRef;
  constructor(private menuService: MenuService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindMenus();
  }

  bindMenus() {
    this.spinner.show();
    this.categories = [];
    this.menuSub = this.menuService.getMenus().subscribe(res => {
      // this.menus = res;
      res.forEach(element => {
        
        if (element.categories && element.categories.length > 0) {
          element.categories.forEach(cat => {
            this.categories.push(cat);
          });
          
        }
      });
      // console.log(this.categories);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.menuSub.unsubscribe();
  }



}
