import { Component, OnInit } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { Banner } from '../models';
import { BannersService } from '../banners.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  baseApiUrl = BaseApiService.baseApiUrl;
  banners: Banner[] = [];
  constructor(private bannersService: BannersService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.bindBanners();
  }

  bindBanners() {
    this.spinner.show();
    this.bannersService.getAll().subscribe(res => {
      this.banners = res;
      console.log(res);
      this.spinner.hide();
    }, err => {
    });
  }

  public getBgImage(index){
    let bgImage = {
      'background-image': "url(" + this.baseApiUrl + this.banners[index].imagePath + ")"
    };
    return bgImage;
  } 

  public gotoPage(routerLink) {
    this.router.navigate([routerLink]);
  }

}
