import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class CashInflowService {

  constructor(private http: HttpClient) { }

  public getCashCollectionByUser(username: string, fromDate: string, toDate: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/CashInflow/GetCashCollectionByUser?username=' +
    username + '&fromDate=' + fromDate + '&toDate=' + toDate;

    return this.http.get<any>(url);
  }

  public getCashInflowsByDateRange(fromDate: string, toDate: string, pageNumber: number, pageSize: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/CashInflow/GetCashInflowsByDateRange?fromDate=' + 
    fromDate + '&toDate=' + toDate + '&pageNumber=' + pageNumber +
    '&pageSize=' + pageSize;

    return this.http.get<any>(url);
  }
}
