import { BaseApiService } from './../base-api.service';
import { Component, OnInit, AfterViewInit, Inject, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '../models';
import { ProductsService } from '../products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  query = '';
  searchByOptions = [ {value:'product', label: 'Product by Location'} , {value:'suplierlocation', label: 'Supplier by Location'}];
  selectedSerachBy = this.searchByOptions[0].value;
  location = '';
  options = [];
  baseApiUrl = BaseApiService.baseApiUrl;

 
  constructor(public appService: AppService,
              private spinner: NgxSpinnerService,
              private productService: ProductsService,
              public dialogRef: MatDialogRef<SearchDialogComponent>) {
               
               }

  ngOnInit() { 
  }



  ngAfterViewInit() {
  
  }

  doAutocomplete(event) {
    this.options = [];
    this.productService.getAutocompleteList(this.query).subscribe(res => {
      res.forEach(item => {
        this.options.push(item);
      });
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {

  }
}
