import { Category } from './models';
import { Observable } from 'rxjs';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  // adds the menu to the database
  addCategory(category: Category): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/category/post';
    const data = {
      menuID: category.menuID,
      categoryName: category.categoryName,
      categoryOrder: category.categoryOrder,
      imagePath: category.imagePath
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<boolean>(url, data, { headers });
  }

  // updates the menu
  updateCategory(category: Category): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/category/put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<boolean>(url, category, { headers });
  }


  // delete a category
  deleteCategory(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/category/delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.delete<any>(url, { headers });
  }

  // gets all menus from api
  getCategories(): Observable<Category[]> {
    const url = BaseApiService.baseApiUrl + 'api/category/getallcategories';
    return this.http.get<Category[]>(url);
  }

  // get category by menu id
  getByMenu(menuId: number): Observable<Category[]> {
    const url = BaseApiService.baseApiUrl + 'api/category/Get/' + menuId;
    return this.http.get<Category[]>(url);
  }

  // get category by id
  getById(id: number): Observable<Category> {
    const url = BaseApiService.baseApiUrl + 'api/category/GetById/' + id;
    return this.http.get<Category>(url);
  }

  uploadCategoryImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/Category/UploadCategoryImage';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    let options = {
      headers: headers
    };

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
}
