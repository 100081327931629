import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { emailValidator, matchingPasswords } from '../theme/utils/app-validators';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private usersService: UsersService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    });
  }

  sendResetLink() {
    this.spinner.show();
    this.usersService.sendPasswordResetLink(this.form.value.email).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', res.message, 'success');
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, err => {
        this.spinner.hide();
        Swal.fire('Error', 'Error occured. Please try again...', 'error');
      });
  }

}
