import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SiteSetting } from '../models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges, AfterViewInit {

  // tslint:disable-next-line: no-input-rename
  @Input('settings') settings: SiteSetting;
  public lat: number;
  public lng: number;
  public zoom: number;
  year: number;

  @ViewChild('map') map: ElementRef;

  phoneNos: string[];
  emails: string[];
  constructor() {
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.settings !== null) {
      this.map.nativeElement.innerHTML = this.settings.mapHtml;
    }
  }

  subscribe() { }
}
