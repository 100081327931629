import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { State } from './models';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor(private http: HttpClient) { }

  getByCountry(id: number): Observable<State[]>{
    const url = BaseApiService.baseApiUrl + 'api/State/Get/' + id;

    return this.http.get<State[]>(url);
  }
}
