import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { Banner } from './models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(private http: HttpClient) { }

  public getAll(): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/Banner/GetBanners';

    return this.http.get<any[]>(url);
  }

  public getById(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Banner/GetBanner/' + id;

    return this.http.get<any>(url);
  }

  public addBanner(banner: Banner): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Banner/Post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<any>(url, banner, { headers });
  }

  public updateBanner(banner: Banner): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Banner/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, banner, { headers });
  }

  public deleteBanner(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Banner/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.delete<any>(url, { headers });
  }

  uploadBannerImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/Banner/UploadBannerImage';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    // let options = {
    //     headers: headers
    // }
    // let token = JSON.parse(localStorage.getItem('token'));
    // formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
}
