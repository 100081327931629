import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CashInflowService } from 'src/app/cash-inflow.service';
import { CustomOrder, Order, RequestOrder } from 'src/app/models';
import { OrdersService } from 'src/app/orders.service';

@Component({
  selector: 'app-cashcollection',
  templateUrl: './cashcollection.component.html',
  styleUrls: ['./cashcollection.component.scss']
})
export class CashcollectionComponent implements OnInit {
  form: FormGroup;
  grossCashCollection = 0;
  expenses = 0;
  netCashCollection = 0;
  toDate: Date;
  fromDate: Date;
  pageNumber = 1;
  pageSize = 10;
  totalOrders = 0;
  pageNumberCustomOrders = 1;
  pageSizeCustomOrders = 10;
  totalCustomOrders = 0;
  pageNumberRequestOrders = 1;
  pageSizeRequestOrders = 10;
  totalRequestOrders = 0;
  ordersSub: Subscription;
  orders: Order[] = [];
  customOrders: CustomOrder[] = [];
  requestOrders: RequestOrder[] = [];
  constructor(private spinner: NgxSpinnerService, private cashInflowService: CashInflowService,
              private fb: FormBuilder, private datePipe: DatePipe, private ordersService: OrdersService) {
                this.createForm();
               }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      status: ['']
    });
  }

  showCashCollection() {
    const username = JSON.parse(localStorage.getItem('token')).username;
    this.spinner.show();
    this.cashInflowService.getCashCollectionByUser(username,
      this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'))
      .subscribe(res => {
        this.spinner.hide();
        this.grossCashCollection = res.grossCashCollection;
        this.expenses = res.expenses;
        this.netCashCollection = res.netCashCollection;
        this.getOrders();
      }, err => {
        this.spinner.hide();
        alert('Error occured. Please try again...');
      });
  }

  getOrders() {

    this.spinner.show();
    this.ordersSub = this.ordersService.getCodOrdersForLogisticPartner(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'),
      this.pageNumber - 1, this.pageSize).subscribe(res => {
        this.totalOrders = res.totalOrders;
        this.orders = res.orders;
        this.totalCustomOrders = res.totalCustomOrders;
        this.customOrders = res.customOrders;
        this.totalRequestOrders = res.totalRequestOrders;
        this.requestOrders = res.requestOrders;
        this.spinner.hide();
      }, err => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
  }

  public onPageChanged(event) {
    this.pageNumber = event;
    this.getOrders();
    window.scrollTo(0, 0);
  }

}
