import { Menu, Slider } from './models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  // adds the menu to the database
  addMenu(menu: Menu): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/menu/post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});


    return this.http.post<boolean>(url, menu, {headers});
  }

  // updates the menu
  updateMenu(menu: Menu): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/menu/put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});


    return this.http.put<boolean>(url, menu, {headers});
  }

  // delete a menu
  deleteMenu(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/menu/delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }

  // gets all menus from api
  getMenus(): Observable<Menu[]> {
    const url = BaseApiService.baseApiUrl + 'api/menu/get';
    return this.http.get<Menu[]>(url);
  }

  getMenusBySeller(username: string): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/menu/GetMenusBySeller?username=' + username;
    return this.http.get<any[]>(url);
  }

  // get menu by id
  getMenu(id: number): Observable<Menu> {
    const url = BaseApiService.baseApiUrl + 'api/menu/get/' + id;
    return this.http.get<Menu>(url);
  }
}
