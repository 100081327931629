import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from './../users.service';
import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { AppSettings, Settings } from '../app-settings';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, DoCheck, OnDestroy {

  public currencies = ['USD', 'EUR'];
  public currency: any;
  public flags = [
    // { name: 'English', image: 'assets/images/flags/gb.svg' },
    // { name: 'German', image: 'assets/images/flags/de.svg' },
    // { name: 'French', image: 'assets/images/flags/fr.svg' },
    // { name: 'Russian', image: 'assets/images/flags/ru.svg' },
    // { name: 'Turkish', image: 'assets/images/flags/tr.svg' }
  ];
  public flag: any;
  name: string;

  usersSub: Subscription = null;

  public settings: Settings;
  constructor(public appSettings: AppSettings, public appService: AppService, private router: Router,
              private usersService: UsersService, private spinner: NgxSpinnerService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];

    this.appService.Data.loggedIn = this.appService.isLoggedIn();

    const token = localStorage.getItem('token');
    if (token !== null && token !== undefined) {
      const username = JSON.parse(localStorage.getItem('token')).username;
      this.spinner.show();
      this.usersSub = this.usersService.getUserByUsername(username).subscribe(res => {
        this.name = res.name;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
      });
    }

  }

  gotoAccountPage() {
    const role = JSON.parse(localStorage.getItem('token')).role;
    if (role === 'Admin') {
      this.router.navigate(['/admin']);
    } else if (role === 'Seller') {
      this.router.navigate(['/seller']);
    } else if (role === 'LogisticPartner') {
      this.router.navigate(['/logisticpartner']);
    } else {
      this.router.navigate(['/account']);
    }
  }

  ngDoCheck(): void {}

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(flag) {
    this.flag = flag;
  }

  ngOnDestroy() {
    if (this.usersSub != null) {
      this.usersSub.unsubscribe();
    }
  }

}
