import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductAttribute } from '../models';

@Component({
  selector: 'app-size-chart',
  templateUrl: './size-chart.component.html',
  styleUrls: ['./size-chart.component.scss']
})
export class SizeChartComponent implements OnInit {
  @ViewChild('sizeChart') sizeChart: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public attributes: ProductAttribute[],
              public dialogRef: MatDialogRef<SizeChartComponent>) { }

  ngOnInit(): void {
    if (this.attributes.filter(a => a.dimensions !== '').length > 0) {
      const attribute = this.attributes.filter(a => a.dimensions !== '' && a.dimensions !== null)[0];
      // this.sizeChart.nativeElement.innerHTML = attribute.dimensions;
      document.getElementById('sizeChart').innerHTML = attribute.dimensions;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
