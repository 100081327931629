import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogisticPartnerHomeComponent } from './logistic-partner-home/logistic-partner-home.component';
import { LogisticPartnerOrdersComponent } from './logistic-partner-orders/logistic-partner-orders.component';
import { LogisticPartnerBuyerComponent } from './logistic-partner-buyer/logistic-partner-buyer.component';
import { LogisticPartnerBestRouteComponent } from './logistic-partner-best-route/logistic-partner-best-route.component';
import { LogisticPartnerAuthGuard } from '../logisticpartner-auth.guard';
import { LogisticPartnerDefaultComponent } from './logistic-partner-default/logistic-partner-default.component';
import { OrderDetailsComponent } from '../logistic-partner/order-details/order-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LogisticPartnerDashboardComponent } from './logistic-partner-dashboard/logistic-partner-dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { CustomOrdersComponent } from './custom-orders/custom-orders.component';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { RequestOrdersComponent } from './request-orders/request-orders.component';
import { RequestOrderDetailsComponent } from './request-order-details/request-order-details.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { AddExpenseComponent } from './add-expense/add-expense.component';
import { CashcollectionComponent } from './cashcollection/cashcollection.component';




const routes: Routes = [
  {path:'', component: LogisticPartnerHomeComponent,
   children:[
    {path:'', component: LogisticPartnerDashboardComponent},
    {path: 'orders', component: LogisticPartnerOrdersComponent},
    {path: 'order-details', component: OrderDetailsComponent},
    {path: 'buyers', component: LogisticPartnerBuyerComponent},
    {path: 'bestroute', component: LogisticPartnerBestRouteComponent},
    {path: 'notifications', component: NotificationsComponent},
    {path: 'order-details/:id', component: OrderDetailsComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'profile-edit', component: ProfileEditComponent},
    {path: 'custom-orders', component: CustomOrdersComponent},
    {path: 'custom-order-details/:id', component: CustomOrderDetailsComponent},
    {path: 'request-orders', component: RequestOrdersComponent},
    {path: 'request-order-details/:id', component: RequestOrderDetailsComponent},
    {path: 'expenses', component: ExpensesComponent},
    {path: 'cashcollection', component: CashcollectionComponent},
    {path: 'add-expense', component: AddExpenseComponent},
   ]},
  {path: 'logistic-dashboard', component: LogisticPartnerDashboardComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogisticPartnerRoutingModule { }
