import { NgxSpinnerService } from 'ngx-spinner';
import { MenuService } from 'src/app/menu.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Menu } from '../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  menus: Menu[] = [];
  menuSub: Subscription;
  // @ViewChild('menuTrigger') menuTrigger: ElementRef;
  constructor(private menuService: MenuService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindMenus();
  }

  bindMenus() {
    this.spinner.show();
    this.menuSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.menuSub.unsubscribe();
  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, el => {
        if (el.children.length > 0) {
          if (el.children[0].classList.contains('mega-menu')) {
            el.classList.add('mega-menu-pane');
          }
        }
    });
  }


}
