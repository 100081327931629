import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ExpensesService } from 'src/app/expenses.service';
import { FileUploadModel } from 'src/app/models/models';
import Swal from 'sweetalert2';
import { ExpensesComponent } from '../expenses/expenses.component';

@Component({
  selector: 'app-add-expense',
  templateUrl: './add-expense.component.html',
  styleUrls: ['./add-expense.component.scss']
})
export class AddExpenseComponent implements OnInit {
  form: FormGroup;
  file: FileUploadModel;
  expenseImageUploaded = false;
  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;
  uploadFileSub: Subscription;
  addExpenseSub: Subscription = null;
  imgValidations = {
    uploadAttempted: false,
    validType: true
  };
  constructor(private expenseService: ExpensesService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder,
              private router: Router,
              private datePipe: DatePipe,
              private appService: AppService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      expenseName: ['', Validators.required],
      expenseDate: ['', Validators.required],
      amount: ['', Validators.required],
    });
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.imgValidations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG")) {
        this.imgValidations.validType = false;
      }

      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validType) {

            let errorMsg = "";
            if (!this.imgValidations.validType) {
              errorMsg += "<div>Invalid file type</div>";
            }

            Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }



  private uploadFile(formData: FormData) {
    this.uploadFileSub = this.expenseService.uploadExpenseImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  saveExpense() {
    if (this.fileName === '') {
      alert('Please upload the file first.');
      return;
    }
    let username = '';
    if (!this.appService.isLoggedIn()) {
      this.router.navigateByUrl('/');
      return;
    }
    username = JSON.parse(localStorage.getItem('token')).username;
    const expense: any = {
      expenseName: this.form.value.expenseName,
      expenseDate: this.datePipe.transform(this.form.value.expenseDate, 'MM/dd/yyyy'),
      imagePath: 'Expenses/' + this.fileName,
      amount: this.form.value.amount,
      addedBy: username
    };

    console.log('expense: ', expense);

    this.spinner.show();
    this.expenseService.addExpense(expense)
    .subscribe(res => {
      this.spinner.hide();
      alert(res.message);
      if (res.added) {
        this.form.reset();
        this.router.navigateByUrl('/logisticpartner/expenses');
      }
    }, err => {
      this.spinner.hide();
      if (err.status == 401) {
        this.router.navigateByUrl('/');
      } else {
        alert('Error occured. Please try again...' + err.statusText);
      }
    });
  }

}
