import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavMenu, Menu } from './models';
import { Location } from '@angular/common';

export const sidenavMenuItems = [
    // new SidenavMenu (1, 'Home', '/', null, null, false, 0),
    // new SidenavMenu (2, 'Fashion', null, null, null, true, 0),
    // new SidenavMenu (10, 'Women', null, null, null, true, 2),
    // new SidenavMenu (11, 'Dresses & Skirts', '/products/dresses & skirts', null, null, false, 10),
    // new SidenavMenu (12, 'Jackets', '/products/jackets', null, null, false, 10),
    // new SidenavMenu (13, 'Tops & Blouses', '/products/tops & blouses', null, null, false, 10),
    // new SidenavMenu (14, 'Shoes & Boots', '/products/shoes & boots', null, null, false, 10),
    // new SidenavMenu (15, 'Knitwear', '/products/knitwear', null, null, false, 10),
    // new SidenavMenu (20, 'Men', null, null, null, true, 2),
    // new SidenavMenu (21, 'T-shirts & Polos', '/products/t-shirts & polos', null, null, false, 20),
    // new SidenavMenu (22, 'Shoe & Boots', '/products/shoe & boots', null, null, false, 20),
    // new SidenavMenu (23, 'Jeans', '/products/jeans', null, null, false, 20),
    // new SidenavMenu (24, 'Coats', '/products/coats', null, null, false, 20),
    // new SidenavMenu (30, 'Kids', null, null, null, true, 2),
    // new SidenavMenu (31, 'Top', '/products/top', null, null, false, 30),
    // new SidenavMenu (32, 'Pants & Shorts', '/products/pants & shorts', null, null, false, 30),
    // new SidenavMenu (33, 'Dresses', '/products/dresses', null, null, false, 30),
    // new SidenavMenu (34, 'Skirts', '/products/skirts', null, null, false, 30),
    // new SidenavMenu (35, 'Set & Body', '/products/set & body', null, null, false, 30),
    // new SidenavMenu (40, 'Accessories', null, null, null, true, 2),
    // new SidenavMenu (41, 'Watches', '/products/watches', null, null, false, 40),
    // new SidenavMenu (42, 'Bags & Wallet', '/products/bags & wallet', null, null, false, 40),
    // new SidenavMenu (43, 'Sunglasses', '/products/sunglasses', null, null, false, 40),
    // new SidenavMenu (44, 'Belts & Hats', '/products/belts & hats', null, null, false, 40),
    // new SidenavMenu (50, 'Jewellery', '/products/jewellery', null, null, false, 0),
    // new SidenavMenu (51, 'Electronics', '/products/electronics', null, null, false, 0),
    // new SidenavMenu (52, 'Sports', '/products/sports', null, null, false, 0),
    // new SidenavMenu (52, 'Motors', '/products/motors', null, null, false, 0),
    // new SidenavMenu (60, 'Pages', null, null, null, true, 0),
    // new SidenavMenu (61, 'All products', '/products', null, null, false, 60),
    // new SidenavMenu (62, 'Product detail', '/products/2/PC All-in-One', null, null, false, 60),
    // new SidenavMenu (63, 'Sign in', '/sign-in', null, null, false, 60),
    // new SidenavMenu (64, '404 Error', '/404', null, null, false, 60),
    // new SidenavMenu (80, 'Contact', '/contact', null, null, false, 0),
    // new SidenavMenu (140, 'Level 1', null, null, null, true, 0),
    // new SidenavMenu (141, 'Level 2', null, null, null, true, 140),
    // new SidenavMenu (142, 'Level 3', null, null, null, true, 141),
    // new SidenavMenu (143, 'Level 4', null, null, null, true, 142),
    // new SidenavMenu (144, 'Level 5', null, 'http://themeseason.com', null, false, 143),
    // new SidenavMenu (200, 'External Link', null, 'http://themeseason.com', '_blank', false, 0)
];

@Injectable({
    providedIn: 'root'
})
export class SidenavMenuService {

    constructor(private location: Location, private router: Router) { }

    public getSidenavMenuItems(menus: Menu[]): Array<SidenavMenu> {
        let menuId = 100;
        let categoryId = 1000;
        let subcategoryId = 2000;
        menus.forEach(menu => {
            sidenavMenuItems.push(new SidenavMenu(menuId, menu.menuName, null, null, null,
                true, 0));
            menu.categories.forEach(category => {
                sidenavMenuItems.push(new SidenavMenu(categoryId, category.categoryName,
                    '/category/' + category.id + '/' + category.categoryName, null,
                    null, true, menuId));
                category.subCategories.forEach(subcategory => {
                    sidenavMenuItems.push(new SidenavMenu(subcategoryId, subcategory.subcategoryName,
                        '/subcategory/' + subcategory.id + '/' + subcategory.subcategoryName,
                        null, null, false, categoryId));
                        subcategoryId++;
                });
                categoryId++;
            });
            menuId++;
        });
        //console.log('SidenavMenuItems: ', sidenavMenuItems);
        return sidenavMenuItems;
    }

    public expandActiveSubMenu(menu: Array<SidenavMenu>) {
        const url = this.location.path();
        const routerLink = decodeURIComponent(url);
        const activeMenuItem = menu.filter(item => item.routerLink === routerLink);
        if (activeMenuItem[0]) {
            let menuItem = activeMenuItem[0];
            while (menuItem.parentId !== 0) {
                const parentMenuItem = menu.filter(item => item.id === menuItem.parentId)[0];
                menuItem = parentMenuItem;
                this.toggleMenuItem(menuItem.id);
            }
        }
    }

    public toggleMenuItem(menuId) {
        const menuItem = document.getElementById('menu-item-' + menuId);
        const subMenu = document.getElementById('sub-menu-' + menuId);
        if (subMenu) {
            if (subMenu.classList.contains('show')) {
                subMenu.classList.remove('show');
                menuItem.classList.remove('expanded');
            } else {
                subMenu.classList.add('show');
                menuItem.classList.add('expanded');
            }
        }
    }

    public closeOtherSubMenus(menu: Array<SidenavMenu>, menuId) {
        const currentMenuItem = menu.filter(item => item.id === menuId)[0];
        menu.forEach(item => {
            if ((item.id !== menuId && item.parentId === currentMenuItem.parentId) ||
                (currentMenuItem.parentId === 0 && item.id !== menuId)) {
                const subMenu = document.getElementById('sub-menu-' + item.id);
                const menuItem = document.getElementById('menu-item-' + item.id);
                if (subMenu) {
                    if (subMenu.classList.contains('show')) {
                        subMenu.classList.remove('show');
                        menuItem.classList.remove('expanded');
                    }
                }
            }
        });
    }

    public closeAllSubMenus() {
        sidenavMenuItems.forEach(item => {
            const subMenu = document.getElementById('sub-menu-' + item.id);
            const menuItem = document.getElementById('menu-item-' + item.id);
            if (subMenu) {
                if (subMenu.classList.contains('show')) {
                    subMenu.classList.remove('show');
                    menuItem.classList.remove('expanded');
                }
            }
        });
    }
}
