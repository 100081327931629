import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from './../contact.service';
import { AppService } from './../app.service';
import { Title } from '@angular/platform-browser';
import { SiteSetting, Contact } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteSettingsService } from 'src/app/site-settings.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../theme/utils/app-validators';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutUsComponent implements OnInit {
  siteSetting: SiteSetting = null;

  constructor(private titleService: Title,
              private appService: AppService) { }

  ngOnInit() {
    this.titleService.setTitle('About Us - ' + this.appService.Data.title);
  }


}
