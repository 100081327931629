import { BaseApiService } from './../base-api.service';
import { Component, OnInit, AfterViewInit, Inject, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from '../app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '../models';
import { ProductsService } from '../products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  productSub: Subscription;
  nos = [1, 2 , 3];
  baseApiUrl = BaseApiService.baseApiUrl;
  public config: SwiperConfigInterface = {};
  product: Product;
  loading = false;
  constructor(public appService: AppService,
              private spinner: NgxSpinnerService,
              private productsService: ProductsService,
              public dialogRef: MatDialogRef<ProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public prod: Product) {
                this.getProductById(prod.id);
               }

  ngOnInit() { 
  }

  public getProductById(id) {
    this.spinner.show();
    this.loading = true;
    this.productSub = this.productsService.getById(id).subscribe(data => {
      this.spinner.hide();
      this.loading = false;
      this.product = data;
    }, err => {
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      freeMode: true,
      freeModeMomentum: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    };
  }

  public close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    console.log('dialog on destory called');
    if (this.productSub != null && this.productSub != undefined) {
      this.productSub.unsubscribe();
    }
 
  }
}
